import {
  generateDefaultStyleProperties,
  generateSkinDefaults,
} from '@wix/editor-elements-panel';
import { dropdownMenuSkinParams } from '../Menu/skinParams/dropdownMenuSkinParams';

export const submenuSkinDefinition = {
  'wixui.skins.Submenu': dropdownMenuSkinParams,
} as const;

export const defaultAttachedSubmenuStyleProperties =
  generateDefaultStyleProperties(submenuSkinDefinition, true);

export const defaultDetachedSubmenuStyleProperties =
  generateDefaultStyleProperties(submenuSkinDefinition);

export default generateSkinDefaults(submenuSkinDefinition);
