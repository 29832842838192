import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import type {
  AppWidgetDefinition,
  AppWidgetProps,
} from '@wix/thunderbolt-components';

export const props = withCompInfo<
  AppWidgetProps,
  AppWidgetDefinition,
  AppWidgetProps
>()(['experiments'], ({ experiments }, carmiProps) => {
  const shouldUseClassName =
    experiments['specs.thunderbolt.useClassnameInResponsiveAppWidget'] === true;
  return {
    ...carmiProps,
    shouldUseClassName,
  };
});

export default createComponentMapperModel({
  props,
});
