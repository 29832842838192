import styles from './SelectableContainer.scss';
import SelectableContainerCommon from '../../SelectableContainer';
import React from 'react';
import type {
  ISelectableContainerImperativeActions,
  ISelectableContainerProps,
} from '../../../types';

const SelectableContainerStylableSkin: React.ForwardRefRenderFunction<
  ISelectableContainerImperativeActions,
  ISelectableContainerProps
> = (props, ref) => {
  return <SelectableContainerCommon {...props} ref={ref} classes={styles} />;
};
export default React.forwardRef(SelectableContainerStylableSkin);
