import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IRichContentViewerControllerProps,
  IRichContentViewerMapperProps,
  IRichContentViewerProps,
  RichContentViewerRefState,
} from '../RichContentViewer.types';
import { METASITE_APP_DEF_ID } from '@wix/thunderbolt-symbols';

const compController = withCompController<
  IRichContentViewerMapperProps,
  IRichContentViewerControllerProps,
  IRichContentViewerProps,
  RichContentViewerRefState
>(({ stateValues, mapperProps }) => {
  const { getAppInstanceByAppDefId } = stateValues;

  const metaSiteInstance = getAppInstanceByAppDefId(METASITE_APP_DEF_ID) || '';

  return {
    ...mapperProps,
    metaSiteInstance,
  };
});

export default compController;
