import React, { useEffect, useState, useRef, useCallback } from 'react';
import classNames from 'clsx';
import Link from '@wix/thunderbolt-elements/src/components/Link/viewer/Link';
import { PauseSmall, PlaySmall } from '@wix/wix-ui-icons-common';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import type { TextMarqueeProps } from '../TextMarquee.types';
import semanticClassNames from '../TextMarquee.semanticClassNames';
import styles from './TextMarquee.scss';
import { TestIds } from './constants';

type AnimationStatus = 'running' | 'paused' | null;
const toggleAnimationStatus = (status: AnimationStatus) => {
  return status === 'paused' ? 'running' : 'paused';
};

const DEFAULT_MULTIPLICATION_FACTOR = 20;

const MarqueeUnit: React.FC<
  TextMarqueeProps & {
    multiplicationFactor: number;
    width?: number | null;
    setWidth: any;
    isOriginal?: boolean;
    index: number;
    dataMarqueeAnimation: string;
  }
> = props => {
  const {
    singleTextItem,
    svgString = '',
    index,
    isOriginal,
    isDecorative,
    svgId,
    allowColorOverride,
    setWidth,
    width,
    styleProperties,
    movementDirection,
  } = props;
  const compRef = React.useRef<HTMLDivElement>(null);
  const dontApplyA11yTags =
    isOriginal && index === 0 && (!isDecorative || singleTextItem.resolvedLink);
  const a11yTags = dontApplyA11yTags
    ? {}
    : { 'aria-hidden': true, 'data-nosnippet': true };

  React.useEffect(() => {
    if (index === 0 && isOriginal) {
      window.requestAnimationFrame(() => {
        const newWidth = compRef.current?.offsetWidth;
        if (newWidth !== width) {
          setWidth(newWidth);
        }
      });
    }
  }, [
    styleProperties?.font,
    index,
    isOriginal,
    setWidth,
    width,
    singleTextItem.text,
    movementDirection,
  ]);

  const formattedText = singleTextItem.text.replaceAll('\n', '');
  return (
    <span
      className={classNames(styles.marqueeUnit, styles.marqueeFlex)}
      ref={compRef}
      {...a11yTags}
      data-testid={TestIds.marqueeUnit}
    >
      <span
        className={classNames(
          styles.marqueeItem,
          styles.marqueeText,
          formatClassNames(semanticClassNames.text),
        )}
        data-testid={TestIds.marqueeItemText}
      >
        <span>{formattedText}</span>
      </span>
      {svgId && svgId !== 'none' && (
        <span
          className={classNames(
            styles.marqueeItem,
            allowColorOverride ? styles.colorSeparator : '',
          )}
          data-testid={TestIds.marqueeItemSeparator}
          dangerouslySetInnerHTML={{ __html: svgString }}
        />
      )}
    </span>
  );
};

const MarqueeUnitContainer: React.FC<
  TextMarqueeProps & {
    multiplicationFactor: number;
    width?: number | null;
    setWidth: any;
    isOriginal?: boolean;
    dataMarqueeAnimation: string;
  }
> = props => {
  const { multiplicationFactor, isOriginal, dataMarqueeAnimation } = props;
  return (
    <span
      className={classNames(styles.marqueeUnitContainer, styles.marqueeFlex)}
      data-marquee-animation={dataMarqueeAnimation}
    >
      {Array(multiplicationFactor)
        .fill(0)
        .map((_, index) => (
          <MarqueeUnit
            {...{ ...props, index, key: `${isOriginal}-${index}` }}
          />
        ))}
    </span>
  );
};

const MarqueeContainer: React.FC<
  TextMarqueeProps & {
    multiplicationFactor: number;
    width?: number | null;
    setWidth: any;
    setAnimationState: any;
    dataMarqueeAnimation: string;
    animationApplied: boolean;
  }
> = props => {
  const {
    pauseOnHover,
    singleTextItem,
    isInEditor,
    setAnimationState,
    animationApplied,
  } = props;
  const shouldPauseOnHover = pauseOnHover || singleTextItem?.resolvedLink;
  const marqueeContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (marqueeContainerRef?.current && animationApplied) {
      const animationState = window
        .getComputedStyle(
          marqueeContainerRef.current?.firstChild as HTMLElement,
        )
        ?.getPropertyValue('animation-play-state');
      setAnimationState(animationState);
    }
  }, [isInEditor, setAnimationState, animationApplied]);

  return (
    <span
      ref={marqueeContainerRef}
      className={classNames(
        styles.marqueeOuter,
        styles.marqueeFlex,
        shouldPauseOnHover && styles.pauseMarquee,
      )}
    >
      <MarqueeUnitContainer {...{ ...props, isOriginal: true }} />
      <MarqueeUnitContainer {...{ ...props }} />
    </span>
  );
};

const TextMarquee: React.FC<TextMarqueeProps> = props => {
  const {
    id,
    singleTextItem,
    isInEditor = false,
    headingLevel,
    styleProperties,
    speed,
    movementDirection,
    translations,
    customClassNames = [],
  } = props;

  const [multiplicationFactor, setMultiplicationFactor] = useState<number>(
    DEFAULT_MULTIPLICATION_FACTOR,
  );
  const [width, setWidth] = useState(null);
  const [animationState, setAnimationState] = useState<AnimationStatus>(null);
  const [dataMarqueeAnimation, setDataMarqueeAnimation] = useState('');

  const compRef = useRef<HTMLDivElement>(null);
  const parentWidth = useRef<number>(0);
  const [animationApplied, setAnimationApplied] = useState<boolean>(false);

  useEffect(() => {
    if (width && parentWidth.current) {
      const distance =
        multiplicationFactor * width +
        multiplicationFactor * parseInt(styleProperties?.spaceBetweenItems, 10);
      const newDuration = distance / (speed || 1);
      compRef?.current?.style.setProperty(
        '--marquee-duration',
        `${newDuration}s`,
      );
    }
  }, [multiplicationFactor, speed, styleProperties?.spaceBetweenItems, width]);

  useEffect(() => {
    if (compRef.current) {
      if (!isInEditor) {
        compRef.current.style.setProperty(
          '--marquee-animation-state',
          'running',
        );
      }
      setDataMarqueeAnimation(movementDirection === 'ltr' ? 'right' : 'left');
      setAnimationApplied(true);
      // TODO : parentWidth does not get updated if parent container width changes (but it does if reparented)
      parentWidth.current = compRef?.current!.offsetWidth;
    }
  }, [isInEditor, movementDirection]);

  useEffect(() => {
    if (parentWidth && width) {
      const factor = Math.ceil(parentWidth.current / width);
      setMultiplicationFactor(factor);
    }
  }, [width, parentWidth]);

  const HeadingLevelTag = headingLevel
    ? (`h${headingLevel}` as keyof JSX.IntrinsicElements)
    : 'p';

  const pauseOnClick = useCallback(() => {
    if (compRef?.current && !isInEditor && animationState) {
      const newAnimationStatus = toggleAnimationStatus(animationState);
      compRef.current.style.setProperty(
        '--marquee-clicked',
        newAnimationStatus,
      );
      setAnimationState(newAnimationStatus);
    }
  }, [isInEditor, animationState]);

  const marqueeContainerProps = {
    ...props,
    multiplicationFactor,
    width,
    setWidth,
    setAnimationState,
    dataMarqueeAnimation,
    animationApplied,
  };

  const playPauseButtonHandler = useCallback(
    (e: any) => {
      if (e.code === 'Space') {
        pauseOnClick();
        e.preventDefault();
      }
    },
    [pauseOnClick],
  );

  return (
    <div
      id={id}
      onClick={pauseOnClick}
      className={classNames(
        styles.marqueeRoot,
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
      ref={compRef}
    >
      <HeadingLevelTag className={styles.headingTag}>
        {singleTextItem?.resolvedLink ? (
          <Link className={styles.marqueeLink} {...singleTextItem.resolvedLink}>
            <MarqueeContainer {...marqueeContainerProps} />
          </Link>
        ) : (
          <MarqueeContainer {...marqueeContainerProps} />
        )}
        <button
          className={classNames(styles.playButton, styles.visuallyHidden)}
          onKeyDown={playPauseButtonHandler}
          aria-label={translations.ariaLabel}
          aria-pressed={animationState === 'running'}
        >
          {animationState === 'running' ? (
            <>
              <span>{translations.pauseLabel}</span>
              <PauseSmall />
            </>
          ) : (
            <>
              <span>{translations.playLabel}</span>
              <PlaySmall />
            </>
          )}
        </button>
      </HeadingLevelTag>
    </div>
  );
};

export default TextMarquee;
