import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  getDesignPanelPreviewStateMap,
} from '@wix/editor-elements-preview-utils';
import type {
  StylableButtonDefinition,
  StylableButtonPlatformProps,
  StylableButtonCarmiProps,
} from '../StylableButton.types';
import { props as viewerPropsMapper } from './StylableButton.mapper';

const props = composeMapper<
  StylableButtonPlatformProps,
  StylableButtonDefinition,
  StylableButtonCarmiProps
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState'],
    remove: ['language', 'mainPageId', 'compId', 'trackClicksAnalytics'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState } = deps;

    const designPanelPreviewStateMap =
      getDesignPanelPreviewStateMap(compPreviewState);

    const stateToPreview =
      designPanelPreviewStateMap?.hoveredStateId ??
      designPanelPreviewStateMap?.selectedStateId;

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      compPreviewState: stateToPreview,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
