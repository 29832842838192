import {
  replaceCompIdPlaceholder,
  replaceContentIds,
} from '@wix/editor-elements-common-utils';
import { isFallbackSvg } from '@wix/editor-elements-corvid-utils';
import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { isSvgTypeUgc } from '../constants';
import type {
  SvgImageProps,
  SvgImageDefinition,
  SvgImageCarmiProps,
  SvgInfo,
} from '../SvgImage.types';

function getAttributeByName(
  rawSvg: string,
  attributeName: keyof React.SVGAttributes<SVGSVGElement>,
): string | undefined {
  // Match only the first <svg> element, ignoring any nested <svg> tags
  const svgMatch = rawSvg.match(/<svg[^>]*>/);
  if (!svgMatch) {
    return undefined;
  }

  // Extract the attributes from the matched <svg> element
  const attributeMatch = svgMatch[0].match(
    new RegExp(`${attributeName}\\s*=\\s*["']?([^"']*)["']?`),
  );
  return attributeMatch ? attributeMatch[1] : undefined;
}

export function getSvgViewBox(
  rawSvg: string,
  { viewBox, bbox, svgType }: SvgInfo,
) {
  const viewBoxAttr = getAttributeByName(rawSvg, 'viewBox');

  if (!viewBoxAttr && viewBox) {
    return viewBox;
  }
  if (!isSvgTypeUgc(svgType) && bbox) {
    return bbox;
  }

  return viewBoxAttr;
}

export function getWidth(rawSvg: string): string | undefined {
  return getAttributeByName(rawSvg, 'width');
}

export function getHeight(rawSvg: string): string | undefined {
  return getAttributeByName(rawSvg, 'height');
}

function cleanSvgAttributes(svgString: string): string {
  const attributesToRemove = [
    'id',
    'aria-label',
    'role',
    'aria-hidden',
    'preserveAspectRatio',
    'data-type',
    'viewBox',
    'xmlns',
    'xmlns:xlink',
    'data-bbox',
  ];

  return svgString
    .trim()
    .replace(/^<\?xml[^>]*\?>\s*/i, '') // Removes the <?xml ... ?> declaration
    .replace(
      /(<svg)([^>]*)(>)/i,
      (_, __, attributes: string, closeTag: string) => {
        let cleanedAttributes = attributes;

        attributesToRemove.forEach(attr => {
          const regex = new RegExp(`\\s*${attr}="[^"]*"`, 'g');
          cleanedAttributes = cleanedAttributes.replace(regex, '');
        });

        return `<svg${cleanedAttributes}${closeTag}`;
      },
    );
}

function transformSvgString(svgString: string, id: string) {
  if (isFallbackSvg(svgString)) {
    return svgString;
  }
  svgString = replaceContentIds(svgString, id);
  svgString = replaceCompIdPlaceholder(svgString, id);
  svgString = cleanSvgAttributes(svgString);

  return svgString;
}

export const props = withCompInfo<
  SvgImageProps,
  SvgImageDefinition,
  SvgImageCarmiProps
>()(
  ['compId', 'compData'],
  ({ compData: { svgId, alt, displayMode }, compId }, carmiData) => {
    const { svgString, svgInfo, link } = carmiData;

    return {
      id: compId,
      svgId,
      link,
      alt,
      svgType: svgInfo.svgType,
      viewBox: getSvgViewBox(svgString, svgInfo),
      svgWidth: getWidth(svgString),
      svgHeight: getHeight(svgString),
      bBox: svgInfo.bbox,
      displayMode,
      svgString: transformSvgString(svgString, compId),
    };
  },
);

const flipTransformStyles = {
  x: 'scale(1, -1)',
  y: 'scale(-1, 1)',
  xy: 'scale(-1, -1)',
  none: null,
} as const;

function getTransformFlipVar(flip: keyof typeof flipTransformStyles) {
  const value = flipTransformStyles[flip];
  if (!value) {
    return {};
  }
  return {
    '--flip': value,
  };
}
type SvgImageCss = {
  '--flip'?: (typeof flipTransformStyles)[keyof typeof flipTransformStyles];
};

export const css = withCompInfo<SvgImageCss, SvgImageDefinition>()(
  ['compProps'],
  ({ compProps }) => {
    const { flip } = compProps;

    return {
      ...getTransformFlipVar(flip),
    };
  },
);

export default createComponentMapperModel({
  props,
  css,
});
