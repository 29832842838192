import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type {
  IMegaMenuContainerItemDefinition,
  MegaMenuContainerItemProps as PlatformProps,
} from '../MegaMenuContainerItem.types';

export const props = withCompInfo<
  PlatformProps,
  IMegaMenuContainerItemDefinition,
  PlatformProps
>()([], (_, carmiData) => {
  return { ...carmiData };
});
export const css = withCompInfo()([], () => {
  return {
    '--rd': '0',
    '--brw': '0',
    '--shd': 'none',
    '--bg': 'transparent',
  };
});

export const uiType = withCompInfo<any, IMegaMenuContainerItemDefinition>()(
  ['isClassicComponent', 'skin'],
  ({ skin, isClassicComponent }) => {
    if (skin === 'Dropdown') {
      return 'Dropdown';
    }
    return isClassicComponent ? 'Classic' : 'Responsive';
  },
);

export default createComponentMapperModel({ props, css, uiType });
