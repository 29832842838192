import { ViewerType as MenuViewerType } from '../Menu/constants';
import { ViewerType as SHMenuViewerType } from '../StylableHorizontalMenu/constants';

export const ViewerType = 'wixui.MegaMenuContainerItem' as const;
export const ParentViewerTypes = [MenuViewerType, SHMenuViewerType] as const;
export const Skins = {
  containerDefaultSkin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  dropdownContainerSkin: 'wixui.skins.Dropdown',
} as const;

export const TranslationKeys = {
  inspector: {
    fixedSizingDisabledTooltip:
      'MISSING_TRANSLATION_KEY_fixedSizingDisabledTooltip',
  },
  layoutPanel: {
    header: 'mega_menu_container_layout_panel_header',
  },
};
