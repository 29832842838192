import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IMusicPlayerControllerProps,
  IMusicPlayerMapperProps,
  IMusicPlayerProps,
  IMusicPlayerStateValues,
} from '../MusicPlayer.types';

export default withCompController<
  IMusicPlayerMapperProps,
  IMusicPlayerControllerProps,
  IMusicPlayerProps,
  IMusicPlayerStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { updateProps } = controllerUtils;
  const { reducedMotion } = stateValues;

  return {
    ...mapperProps,
    onPlay: () => {
      updateProps({ isPlaying: true });
    },
    onPause: () => {
      updateProps({ isPlaying: false });
    },
    onEnded: () => {
      updateProps({ isPlaying: false });
    },
    onTimeUpdated: event => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { type, ...payload } = event;
      updateProps({ currentTime: payload.currentTime });
    },
    reducedMotion,
  };
});
