import React from 'react';
import type { SelectableContainerInputComponentProps } from '../../../types';
import { useSelectableContainerContext } from '../../../../SelectableContainer/SelectableContainer.context';
import SelectableContainerInputCommon from '../../SelectableContainerInput.common';
import styles from './SelectableContainerInput.scss';
import classNames from 'clsx';

const SelectableContainerInput: React.FC<
  SelectableContainerInputComponentProps
> = props => {
  const { selected, inputType } = useSelectableContainerContext();
  const { selectedPreview = false } = props;
  const isSelected = selected || selectedPreview;

  const extendedProps = {
    ...props,
    classes: {
      ...styles,
      root: classNames({
        [styles.selected]: isSelected,
        [styles.checkbox]: inputType === 'checkbox',
      }),
    },
  };
  return <SelectableContainerInputCommon {...extendedProps} />;
};

export default SelectableContainerInput;
