import type { SkinDefinition } from '@wix/editor-elements-panel/src/adapters/types';
import { backgroundDefaultValue, hover, regular } from './common';
import { cssPropertyToDefaultValueMap } from '@wix/editor-elements-panel';

export const scrollButtonSkinParams: SkinDefinition = {
  // scroll button background regular
  'scroll-button-background': {
    type: 'BACKGROUND_FILL',
    defaultValue: backgroundDefaultValue,
    state: regular,
  },
  'scroll-button-hover-background': {
    type: 'BACKGROUND_FILL',
    state: hover,
  },
  // scroll button border regular
  'scroll-button-border-left': {
    type: 'BORDER_SIDE',
    defaultValue: '0px none #000000',
    state: regular,
  },
  'scroll-button-border-right': {
    type: 'BORDER_SIDE',
    defaultValue: '0px none #000000',
    state: regular,
  },
  'scroll-button-border-top': {
    type: 'BORDER_SIDE',
    defaultValue: '0px none #000000',
    state: regular,
  },
  'scroll-button-border-bottom': {
    type: 'BORDER_SIDE',
    defaultValue: '0px none #000000',
    state: regular,
  },
  // scroll button border hover
  'scroll-button-hover-border-left': {
    type: 'BORDER_SIDE',
    state: hover,
  },
  'scroll-button-hover-border-right': {
    type: 'BORDER_SIDE',
    state: hover,
  },
  'scroll-button-hover-border-top': {
    type: 'BORDER_SIDE',
    state: hover,
  },
  'scroll-button-hover-border-bottom': {
    type: 'BORDER_SIDE',
    state: hover,
  },
  // scroll button border radius regular
  'scroll-button-border-radius': {
    type: 'SIDES',
    defaultValue: cssPropertyToDefaultValueMap['border-radius'],
    state: regular,
  },
  // scroll button border radius hover
  'scroll-button-hover-border-radius': {
    type: 'SIDES',
    state: hover,
  },
  // scroll button box shadow
  'scroll-button-box-shadow': {
    type: 'BOX_SHADOW',
    defaultValue: 'none',
    state: regular,
  },
  'scroll-button-hover-box-shadow': {
    type: 'BOX_SHADOW',
    state: hover,
  },
  // scroll button icon regular
  'scroll-button-icon-display': {
    type: 'CSSString',
    defaultValue: 'unset',
    state: regular,
  },
  'scroll-button-icon-size': {
    type: 'SIZE',
    defaultValue: '10px',
    state: regular,
  },
  'scroll-button-icon-color': {
    type: 'CSS_COLOR',
    defaultValue: 'revert',
    state: regular,
  },
  'scroll-button-icon-rotation': {
    type: 'CSSString',
    defaultValue: '0',
    state: regular,
  },
  // scroll button icon hover
  'scroll-button-hover-icon-display': {
    type: 'CSSString',
    state: hover,
  },
  'scroll-button-hover-icon-size': {
    type: 'SIZE',
    state: hover,
  },
  'scroll-button-hover-icon-color': {
    type: 'CSS_COLOR',
    state: hover,
  },
  'scroll-button-hover-icon-rotation': {
    type: 'CSSString',
    state: hover,
  },
  // scroll button padding regular
  'scroll-button-padding-top': {
    type: 'SIZE',
    defaultValue: '0px',
    state: regular,
  },
  'scroll-button-padding-right': {
    type: 'SIZE',
    defaultValue: '0px',
    state: regular,
  },
  'scroll-button-padding-bottom': {
    type: 'SIZE',
    defaultValue: '0px',
    state: regular,
  },
  'scroll-button-padding-left': {
    type: 'SIZE',
    defaultValue: '0px',
    state: regular,
  },
  // scroll button padding hover
  'scroll-button-hover-padding-top': {
    type: 'SIZE',
    state: hover,
  },
  'scroll-button-hover-padding-right': {
    type: 'SIZE',
    state: hover,
  },
  'scroll-button-hover-padding-bottom': {
    type: 'SIZE',
    state: hover,
  },
  'scroll-button-hover-padding-left': {
    type: 'SIZE',
    state: hover,
  },
};
