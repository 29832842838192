import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  getFromColorMap,
  isThemeColor,
} from '@wix/thunderbolt-commons/dist/color';
import type { CompInfo } from '@wix/thunderbolt-components-native';
import type {
  TextMarqueeProps,
  TextMarqueeDefinition,
  TextMarqueeCarmiProps,
} from '../TextMarquee.types';
import { TranslationKeys } from './constants';
import { getScaledFont } from '@wix/editor-elements-common-utils';

const getResolvedColorForSite =
  (siteColors: CompInfo['siteColors']) =>
  (color?: string, fallbackColor?: string) => {
    if (!color) {
      return fallbackColor;
    }
    return isThemeColor(color) ? getFromColorMap(color, siteColors) : color;
  };

export const props = withCompInfo<
  TextMarqueeProps,
  TextMarqueeDefinition,
  TextMarqueeCarmiProps
>()(
  ['compData', 'styleProperties', 'translate'],
  ({ compData, styleProperties, translate }, carmiData) => {
    const {
      headingLevel,
      speed,
      isDecorative,
      svgId,
      id,
      pauseOnHover,
      movementDirection,
    } = compData;
    const { svgString, svgInfo, resolvedItems } = carmiData;
    const singleTextItem = resolvedItems[0];
    const separatorHasMultiColor = !!svgInfo?.color2;
    const allowColorOverride = !separatorHasMultiColor;

    return {
      id,
      pauseOnHover,
      isDecorative,
      svgId,
      headingLevel,
      speed,
      allowColorOverride,
      singleTextItem,
      svgString,
      styleProperties,
      movementDirection,
      translations: {
        ariaLabel: translate(
          TranslationKeys.namespace,
          TranslationKeys.ariaLabel,
        ),
        pauseLabel: translate(
          TranslationKeys.namespace,
          TranslationKeys.pauseLabel,
        ),
        playLabel: translate(
          TranslationKeys.namespace,
          TranslationKeys.playLabel,
        ),
      },
    };
  },
);

export const css = withCompInfo()(
  [
    'styleProperties',
    'formatCssValue',
    'siteColors',
    'isMobileView',
    'siteFonts',
    'compLayout',
    'isOneDocMigrated',
    'compData',
  ],
  compInfo => {
    const {
      styleProperties,
      formatCssValue,
      siteColors,
      isMobileView,
      compData,
    } = compInfo;

    const {
      color,
      spaceBetweenItems,
      textHighlight,
      separatorFillColor,
      separatorSize,
      spx,
      font,
      minFontSize,
      maxFontSize,
    } = styleProperties;

    const { textDirection } = compData;

    const parsedColor = color || font;
    const getResolvedColor = getResolvedColorForSite(siteColors);
    const resolvedColor = getResolvedColor(parsedColor);
    const resolvedHighlightColor = getResolvedColor(textHighlight);
    const resolvedSeparatorFillColor = getResolvedColor(
      separatorFillColor,
      resolvedColor,
    );

    const separatorSizePercent = separatorSize
      ? parseInt(separatorSize, 10) / 100
      : 1;

    let scaleFontSize = null;
    if (minFontSize && maxFontSize) {
      scaleFontSize = `clamp(${minFontSize}, 1em, ${maxFontSize})`;
    } else if (minFontSize) {
      scaleFontSize = `max(${minFontSize}, 1em)`;
    } else if (maxFontSize) {
      scaleFontSize = `min(1em, ${maxFontSize})`;
    }

    return {
      '--color': resolvedColor,
      '--textHighlight': resolvedHighlightColor,
      '--spaceBetweenItems': formatCssValue(spaceBetweenItems, spx),
      '--separatorFillColor': resolvedSeparatorFillColor,
      '--separator-scale': separatorSizePercent,
      '--text-direction': textDirection,
      ...(scaleFontSize && { '--font-size': scaleFontSize }),
      ...(isMobileView && { '--font': getScaledFont(compInfo, 'font') }),
    };
  },
);

export default createComponentMapperModel({
  props,
  css,
});
