import * as React from 'react';
import semanticClassNames from '../ProductSpecification.semanticClassNames';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import styles from './style/ProductSpecification.component.scss';
import type { ISpecificationGroup } from '../types';
import type { IProductSpecificationProps } from '../ProductSpecification.props';
import SpecificationGroup from './SpecificationGroup';

const ProductSpecification: React.FC<IProductSpecificationProps> = props => {
  const { id, items } = props;

  return (
    <div
      id={id}
      className={formatClassNames(semanticClassNames.root, styles.root)}
    >
      {items.map((group: ISpecificationGroup, groupIndex) => (
        <SpecificationGroup
          key={`${group.title}-${groupIndex}`}
          group={group}
        />
      ))}
    </div>
  );
};

export default ProductSpecification;
