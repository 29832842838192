import * as React from 'react';
import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import type { IResponsiveAppWidgetProps } from '../../../AppWidget.types';
import ResponsiveContainer from '../../../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';

const ResponsiveAppWidget: React.FC<IResponsiveAppWidgetProps> = props => {
  const {
    id,
    children,
    containerProps,
    containerRootClassName = '',
    className = '',
    shouldUseClassName,
  } = props;

  return (
    <div
      id={id}
      className={classNames(containerRootClassName, {
        [className]: shouldUseClassName,
      })}
      {...getDataAttributes(props)}
    >
      <ResponsiveContainer {...containerProps}>{children}</ResponsiveContainer>
    </div>
  );
};

export default ResponsiveAppWidget;
