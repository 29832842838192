import React from 'react';
import {
  formatClassNames,
  INNER_FOCUS_RING_CLASSNAME,
} from '@wix/editor-elements-common-utils';

import type { ClassNames, BreadcrumbsProps } from '../../../Breadcrumbs.types';

import semanticClassNames from '../../../Breadcrumbs.semanticClassNames';
import { st, classes } from '../../style/Breadcrumbs.component.st.css';
import BreadcrumbsCore from '../Breadcrumbs.core';

export const StylableBreadcrumbs: React.ForwardRefRenderFunction<
  HTMLDivElement,
  BreadcrumbsProps
> = (props, ref) => {
  const {
    className,
    customClassNames = [],
    stylableClassName,
    showOnlyPreviousPageOnMobile,
  } = props;

  const contentClassName = showOnlyPreviousPageOnMobile
    ? classes['only-previous']
    : '';

  const classNames: ClassNames = {
    root: className,
    nav: st(
      classes.root,
      stylableClassName,
      formatClassNames(semanticClassNames.root, ...customClassNames),
    ),
    ol: st(classes.list, contentClassName),
    icon: classes.icon,
    breadcrumb: classes.breadcrumb,
    breadcrumbIcon: st(classes.breadcrumb, classes['breadcrumb-icon']),
    separator: classes.separator,
    breadcrumbContent: st(
      classes['breadcrumb-content'],
      INNER_FOCUS_RING_CLASSNAME,
    ),
    breadcrumbContentCurrent: st(
      classes['breadcrumb-content'],
      {
        isCurrent: true,
      },
      INNER_FOCUS_RING_CLASSNAME,
    ),
    label: classes.label,
  };

  return <BreadcrumbsCore {...props} ref={ref} classNames={classNames} />;
};

export default React.forwardRef(StylableBreadcrumbs);
