import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type { WPhotoDefinition } from '@wix/thunderbolt-components';
import type { IWPhotoMapperProps, IWPhotoStateValues } from '../WPhoto.types';
import { TranslationKeys } from '../constants';
import { getResponsiveImageProps } from '../../Image/viewer/Image.utils';

export const props = withCompInfo<
  IWPhotoMapperProps,
  WPhotoDefinition,
  IWPhotoMapperProps
>()(
  [
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'experiments',
    'trackClicksAnalytics',
    'translate',
  ],
  ({ experiments, translate, ...compPropsRest }, carmiData) => {
    const {
      displayMode,
      uri,
      width,
      height,
      crop,
      focalPoint,
      quality,
      upscaleMethod,
      hasAnimation,
      alignType,
      onClickBehavior,
      name,
      responsiveImageProps,
    } = carmiData;

    const shouldCreateZoomedOverride =
      responsiveImageProps && onClickBehavior === 'zoomAndPanMode';

    const zoomedImageResponsiveOverride = shouldCreateZoomedOverride
      ? getResponsiveImageProps(
          {
            displayMode,
            uri,
            width,
            height,
            crop,
            focalPoint,
            quality,
            upscaleMethod,
            hasAnimation,
            alignType,
            name,
          },
          width,
          height,
        )
      : {};

    return {
      ...carmiData,
      ...compPropsRest,
      popupA11yExperimentEnabled:
        !!experiments?.[
          'specs.thunderbolt.A11yWPhotoPopupSemanticsAndKeyboardOperability'
        ],

      translations: {
        expandAriaLabel:
          translate(
            TranslationKeys.ARIA_LABEL_NAMESPACE,
            TranslationKeys.EXPAND_LABEL_KEY,
          ) || TranslationKeys.EXPAND_FALLBACK_LABEL,
        zoomInButtonAriaLabel:
          translate(
            TranslationKeys.ARIA_LABEL_NAMESPACE,
            TranslationKeys.ZOOM_IN_LABEL_KEY,
          ) || TranslationKeys.ZOOM_IN_FALLBACK_LABEL,
      },
      zoomedImageResponsiveOverride,
    };
  },
);

const stateRefs = withStateRefsValues<keyof IWPhotoStateValues>([
  'reportBi',
  'experiments',
]);

export default createComponentMapperModel({ props, stateRefs });
