import {
  PASS_MIN_LEN,
  PASS_MAX_LEN,
} from '@wix/editor-elements-library/src/components/MemberLoginDialog/viewer/utils';
import { VerificationCodeDialogTranslationKeys } from '@wix/editor-elements-library/src/components/VerificationCodeDialog/viewer/constants';
import type {
  IServerErrorResponse,
  ITranslationKeys,
  IValidationErrorResponse,
} from '../SiteMembersInput.types';

const keys = {
  retypePassword: {
    match: 'membersErrorRetypePassword',
  },
  password: {
    required: 'membersErrorPasswordRequired',
    ascii: 'membersErrorPasswordAscii',
    signupLength: 'membersErrorPasswordSignupLength',
    loginLength: 'membersErrorPasswordLoginLength',
  },
  email: {
    required: 'membersErrorEmailRequired',
    format: 'membersErrorEmailFormat',
  },
};

export const serverErrorsHandler = (
  error: IValidationErrorResponse | IServerErrorResponse | string | number,
): string => {
  let errorCode: string;
  if (typeof error === 'object') {
    errorCode = handlePlatformizedError(error);
  } else {
    errorCode = error as string;
  }
  errorCode = errorCode.toString().replace('-', '');
  return errorCode.includes('membersError')
    ? errorCode
    : `membersServerError_${errorCode}`;
};

const handlePlatformizedError = (error: any): string => {
  const details = error.details;
  const validationError = details?.validationError?.fieldViolations[0];
  if (validationError?.data?.type === 'EMAIL') {
    return keys.email.format;
  }
  if (
    validationError?.field === 'password' &&
    validationError?.ruleName === 'VALUE_TOO_SHORT'
  ) {
    return keys.password.signupLength;
  }
  if (validationError?.ruleName) {
    return validationError.ruleName;
  }

  return (
    details?.errorcode ||
    details?.applicationError?.code ||
    details?.errorCode ||
    'membersErrorGeneral'
  );
};

const isValidEmail = (emailToTest: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(emailToTest);
};

const isAscii = (char: string) => char.charCodeAt(0) < 127;
const isAsciiOnlyInput = (value: string) => Array.from(value).every(isAscii);
const isPasswordBlank = (value: string, translations: ITranslationKeys) => {
  if (value.length !== 0) {
    return null;
  }
  return translations[keys.password.required];
};
const isPasswordAsciiOnly = (value: string, translations: ITranslationKeys) => {
  if (isAsciiOnlyInput(value)) {
    return null;
  }

  return translations[keys.password.ascii];
};
const isPasswordLengthLogin = (
  value: string,
  translations: ITranslationKeys,
) => {
  if (value.length < PASS_MIN_LEN) {
    return translations[keys.password.loginLength];
  }

  return null;
};
const isPasswordLengthSignup = (
  value: string,
  translations: ITranslationKeys,
) => {
  if (value.length < PASS_MIN_LEN || value.length >= PASS_MAX_LEN) {
    return translations[keys.password.signupLength]
      ?.replace('{0}', PASS_MIN_LEN.toString())
      .replace('{1}', PASS_MAX_LEN.toString());
  }

  return null;
};

export const validateSiteMembersPassword = {
  signup: (
    value: string,
    translations: ITranslationKeys,
  ): string | null | undefined => {
    return (
      isPasswordBlank(value, translations) ||
      isPasswordLengthSignup(value, translations) ||
      isPasswordAsciiOnly(value, translations)
    );
  },
  login: (
    value: string,
    translations: ITranslationKeys,
  ): string | null | undefined => {
    return (
      isPasswordBlank(value, translations) ||
      isPasswordLengthLogin(value, translations) ||
      isPasswordAsciiOnly(value, translations)
    );
  },
};

export const validateSiteMembersRetypePassword = (
  value: string,
  password: string,
  translations: ITranslationKeys,
): string | null | undefined => {
  const errorMessage = validateSiteMembersPassword.signup(value, translations);
  if (errorMessage) {
    return errorMessage;
  }
  if (value !== password) {
    return translations[keys.retypePassword.match];
  }
  return null;
};

export const validateSiteMembersEmail = (
  value: string,
  translations: ITranslationKeys,
): string | null | undefined => {
  if (value.length === 0) {
    return translations[keys.email.required];
  }
  if (!isValidEmail(value)) {
    return translations[keys.email.format];
  }

  return null;
};

export const figureFallbackErrorMessage = (
  FailureReason: string,
  translations: ITranslationKeys,
) => {
  switch (FailureReason) {
    case 'NOT_FOUND':
      return translations[VerificationCodeDialogTranslationKeys.Code_Not_Found];
    case 'BAD_CODE':
    case 'VERIFICATION_FAILED':
      return translations[VerificationCodeDialogTranslationKeys.Code_Bad_Code];
    case '-19901':
      return translations[
        VerificationCodeDialogTranslationKeys.To_Many_Requests
      ];
    default:
      return '';
  }
};
