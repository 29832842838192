import { withCompController } from '@wix/editor-elements-integrations';
import { mapConsentPolicyStateRefValues } from '@wix/thunderbolt-elements/src/components/ConsentPolicyWrapper/viewer/utils';
import type {
  GoogleMapMapperProps,
  GoogleMapProps,
  GoogleMapStateRefs,
  GoogleMapControllerProps,
} from '../GoogleMap.types';

export const innerControllerFunction = ({
  stateValues,
  mapperProps,
}: {
  stateValues: GoogleMapStateRefs;
  mapperProps: GoogleMapMapperProps;
}) => ({
  ...mapperProps,
  ...mapConsentPolicyStateRefValues(stateValues),
});

export default withCompController<
  GoogleMapMapperProps,
  GoogleMapControllerProps,
  GoogleMapProps,
  GoogleMapStateRefs
>(innerControllerFunction);
