import {
  MenuDisplayMode,
  VerticalDropdownDisplay,
  MenuOrientation,
  MenuOverflow,
} from '../../constants';
import type {
  MenuOrientationType,
  IMenuOrientationCssVars,
  MenuDisplayModeType,
  MenuOverflowType,
  MenuVerticalDropdownDisplayType,
  IMenuVerticalDropdownDisplayCssVars,
  IMenuOverflowCssVars,
  MenuJustificationType,
  IMenuJustificationCssVars,
  IMenuDisplayModeCssVars,
} from '../../Menu.types';

const horizontalCssVars: IMenuOrientationCssVars = {
  '--container-flex-direction': 'row',
  '--item-wrapper-width': 'unset',
  '--menu-items-row-gap': 'var(--menu-items-cross-axis-gap)',
  '--menu-items-column-gap': 'var(--menu-items-main-axis-gap)',
  '--horizontal-menu-item-divider': 'var(--item-divider)',
  '--vertical-menu-item-divider': 'none',
  '--container-pointer-events': 'initial',
  '--menu-item-height': '100%',
  '--item-wrapper-display': 'block',
  '--container-width': 'auto',
  '--horizontal-menu-dropdown-display': 'unset',
  '--vertical-menu-dropdown-display': 'none',
  '--item-icon-transform': 'none',
  '--empty-dropdown-item-icon-display': 'unset',
};

const verticalCssVars: IMenuOrientationCssVars = {
  '--container-flex-direction': 'column',
  '--item-wrapper-width': '100%',
  '--menu-items-row-gap': 'var(--menu-items-main-axis-gap)',
  '--menu-items-column-gap': 'var(--menu-items-cross-axis-gap)',
  '--horizontal-menu-item-divider': 'none',
  '--vertical-menu-item-divider': 'var(--item-divider)',
  '--container-pointer-events': 'none',
  '--menu-item-height': 'auto',
  '--item-wrapper-display': 'var(--item-wrapper-display-alignment)',
  '--container-width': '100%',
  '--horizontal-menu-dropdown-display': 'none',
  '--vertical-menu-dropdown-display': 'unset',
  '--item-icon-transform': 'unset',
  '--empty-dropdown-item-icon-display': 'none',
};

const verticalAlwaysOpenCssVars: IMenuVerticalDropdownDisplayCssVars = {
  '--vertical-item-icon-display': 'none',
  '--vertical-dropdown-visibility': 'visible',
  '--vertical-dropdown-height': 'unset',
};

const verticalExpandCollapsedCssVars: IMenuVerticalDropdownDisplayCssVars = {
  '--vertical-item-icon-display': 'flex',
  '--vertical-dropdown-visibility': 'hidden',
  '--vertical-dropdown-height': '0',
};

export const justificationToCssVarsMap: Record<
  MenuJustificationType,
  IMenuJustificationCssVars
> = {
  justify: {
    '--item-width': 'auto',
    '--menu-items-flex-grow': 1,
    '--item-wrapper-display-alignment': 'block',
  },
  none: {
    '--item-width': 'fit-content',
    '--menu-items-flex-grow': 0,
    '--item-wrapper-display-alignment': 'flex',
  },
};

export const orientationToCssVarsMap: Record<
  MenuOrientationType,
  IMenuOrientationCssVars
> = {
  [MenuOrientation.Horizontal]: horizontalCssVars,
  [MenuOrientation.Vertical]: verticalCssVars,
};

export const dropdownDisplayTypeToCssVarMap: Record<
  MenuVerticalDropdownDisplayType,
  IMenuVerticalDropdownDisplayCssVars
> = {
  [VerticalDropdownDisplay.AlwaysOpen]: verticalAlwaysOpenCssVars,
  [VerticalDropdownDisplay.ExpandCollapse]: verticalExpandCollapsedCssVars,
};

export const verticalOverflowCssVars = {
  'min-width': 'min-content',
};

export const horizontalOverflowToCssVarsMap: Record<
  MenuOverflowType,
  IMenuOverflowCssVars
> = {
  [MenuOverflow.Scroll]: {
    'min-width': 'initial',
    '--container-overflow-x': 'auto',
    '--container-flex-wrap': 'nowrap',
  },
  [MenuOverflow.Wrap]: {
    'min-width': 'min-content',
    '--container-overflow-x': 'visible',
    '--container-flex-wrap': 'wrap',
  },
};

export const displayModeToCssVarsMap: Record<
  MenuDisplayModeType,
  IMenuDisplayModeCssVars
> = {
  [MenuDisplayMode.Navbar]: {
    '--navbar-display': 'unset',
    '--hamburger-menu-root-display': 'none',
  },
  [MenuDisplayMode.Hamburger]: {
    '--navbar-display': 'none',
    '--hamburger-menu-root-display': 'unset',
  },
};
