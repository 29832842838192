import type { SvgType } from '@wix/document-services-types';

export const ViewerType = 'wixui.SvgImage' as const;
export const PropertiesType = 'SvgImageProperties' as const;

export const SVG_TYPES = {
  SHAPE: 'shape',
  TINT: 'tint',
  COLOR: 'color',
  UGC: 'ugc',
} as const satisfies Record<Uppercase<SvgType>, SvgType>;

type SvgTypeOrString = SvgType | (string & {}) | null | undefined;

export const isSvgTypeShape = (svgType: SvgTypeOrString): svgType is 'shape' =>
  svgType === SVG_TYPES.SHAPE;
export const isSvgTypeTint = (svgType: SvgTypeOrString): svgType is 'tint' =>
  svgType === SVG_TYPES.TINT;
export const isSvgTypeUgc = (svgType: SvgTypeOrString): svgType is 'ugc' =>
  svgType === SVG_TYPES.UGC;
export const isSvgTypeColor = (svgType: SvgTypeOrString): svgType is 'color' =>
  svgType === SVG_TYPES.COLOR;

// from santa-editor/packages/constants/base/baseConstants.ts `VECTOR_SHAPE_DEFAULT_PATH`
export const SVG_IMAGE_SHAPE_DEFAULT_PATH =
  'public/ab62ca24cd194952aad0707eead0c0f7/21555df8496d4c3c86bae2b4fcba13f1';
