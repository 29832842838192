import * as React from 'react';
import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import Image from '../../../Image/viewer/Image';
import type { MediaZoomProps } from '../TouchMediaZoom.types';
import Link from '../../../Link/viewer/Link';
import { TestIds } from '../constants';
import { useGesture } from '../../../../providers/useGesture/useGesture';

import styles from './styles/TouchMediaZoom.scss';

type TouchOrMouseEvent = React.MouseEvent | TouchEvent;

const TouchMediaZoomBase: React.FC<MediaZoomProps> = props => {
  const {
    id,
    className,
    onClose,
    images,
    selectedImageIndex,
    onImageChangeCallback,
    popupA11yExperimentEnabled,
    translations,
  } = props;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const imageInfoBoxRef = React.useRef<HTMLDivElement>(null);
  const [isImageReady, setIsImageReady] = React.useState(false);

  const getNextItem = () =>
    selectedImageIndex + 1 < images.length ? selectedImageIndex + 1 : 0;
  const getPrevItem = () =>
    selectedImageIndex ? selectedImageIndex - 1 : images.length - 1;

  const onNextClickHandler = (event: TouchOrMouseEvent) => {
    onItemChangeHandler(getNextItem(), event);
  };
  const onPrevClickHandler = (event: TouchOrMouseEvent) => {
    onItemChangeHandler(getPrevItem(), event);
  };

  useGesture('onSwipeRight', onPrevClickHandler, containerRef);
  useGesture('onSwipeLeft', onNextClickHandler, containerRef);

  const onItemChangeHandler = (
    newSelectedImageIndex: number,
    event?: TouchOrMouseEvent,
  ) => {
    setIsImageReady(false);

    setTimeout(() => {
      const newDataItemId = images[newSelectedImageIndex].id;
      if (event) {
        event.stopPropagation?.();
        event.preventDefault?.();
      }
      onImageChangeCallback(newDataItemId);
    }, 250);
  };

  const disableOnClose: React.MouseEventHandler = event => {
    if (!(event.target instanceof HTMLAnchorElement)) {
      event.stopPropagation();
    }
  };

  const onCloseHandler = () => {
    onClose(false);
  };

  const containerHandlers =
    images.length === 1 ? { onClick: onCloseHandler } : {};

  const selectedImage = images[selectedImageIndex];

  const linkOnClickHandler =
    images.length > 1 && !selectedImage?.link?.linkPopupId
      ? onCloseHandler
      : undefined;

  const { link, title, description, width, height } = selectedImage;

  const renderImageInfo = () => {
    const viewPortDimensions = containerRef.current?.getBoundingClientRect();
    const isLandScape =
      viewPortDimensions &&
      viewPortDimensions.width > viewPortDimensions.height;

    return (
      (title || description || link) &&
      isImageReady && (
        <div
          className={classNames(
            {
              [styles.hidden]: isLandScape,
            },
            styles.info,
          )}
          onClick={disableOnClose}
          ref={imageInfoBoxRef}
        >
          {title && (
            <p itemProp="name" className={styles.infoField}>
              {title}
            </p>
          )}
          {description && (
            <p itemProp="description" className={styles.infoField}>
              {description}
            </p>
          )}
          {link && (
            <Link
              {...link}
              dataTestId={TestIds.link}
              className={styles.link}
              onClick={linkOnClickHandler}
            >
              Go to link
            </Link>
          )}
        </div>
      )
    );
  };

  React.useEffect(() => {
    if (popupA11yExperimentEnabled) {
      const previousFocusedElement = document.activeElement as HTMLElement;
      if (containerRef.current) {
        containerRef.current.focus();
      }
      return () => previousFocusedElement && previousFocusedElement.focus();
    }
    return () => null;
  }, [popupA11yExperimentEnabled]);

  const rootDivProps = {
    ...getDataAttributes(props),
    className: classNames(className, styles.root, styles.mobile),
    ...containerHandlers,
    'data-testid': TestIds.container,
    ref: containerRef,
    id,
    ...(popupA11yExperimentEnabled && {
      tabIndex: -1,
      role: 'dialog',
      'aria-modal': true,
      ...(selectedImage.alt
        ? { 'aria-labelledby': selectedImage.containerId }
        : { 'aria-label': translations.expandedLabel }),
    }),
  };

  const renderOriginCloseButton = () => (
    <div
      className={styles.xButton}
      data-testid={TestIds.close}
      onClick={images.length > 1 ? onCloseHandler : undefined}
    >
      <svg
        viewBox="0 0 180 180"
        className={styles.svgButtonClose}
        tabIndex={0}
        role="button"
        aria-label="close"
        data-testid={TestIds.closeIcon}
      >
        <path d="M5 5 L175 175 M175 5 L5 175" />
      </svg>
    </div>
  );

  const renderA11yCloseButton = () => (
    <button
      className={styles.xButton}
      data-testid={TestIds.closeButton}
      onClick={images.length > 1 ? onCloseHandler : undefined}
      aria-label={translations.closeLabel}
    >
      <svg
        viewBox="0 0 180 180"
        className={styles.svgButtonClose}
        data-testid={TestIds.closeIcon}
      >
        <path d="M5 5 L175 175 M175 5 L5 175" />
      </svg>
    </button>
  );

  const renderCloseButton = popupA11yExperimentEnabled
    ? renderA11yCloseButton
    : renderOriginCloseButton;

  const renderOriginalNavButtons = () => (
    <>
      <div
        className={styles.nextButton}
        onClick={onNextClickHandler}
        data-testid={TestIds.next}
      >
        <svg
          viewBox="0 0 180 310"
          className={styles.svgNavButton}
          tabIndex={0}
          role="button"
          aria-label="next"
          data-testid={TestIds.nextIcon}
        >
          <path d="M10 10 L170 161 M170 150 L10 300" />
        </svg>
      </div>
      <div
        className={styles.prevButton}
        onClick={onPrevClickHandler}
        data-testid={TestIds.prev}
      >
        <svg
          viewBox="0 0 180 310"
          className={styles.svgNavButton}
          tabIndex={0}
          role="button"
          aria-label="previous"
          data-testid={TestIds.prevIcon}
        >
          <path d="M170 10 L10 161 M10 150 L170 300" />
        </svg>
      </div>
    </>
  );

  const renderA11yNavButtons = () => (
    <>
      <div
        className={styles.accessibleNextButton}
        onClick={onNextClickHandler}
        data-testid={TestIds.next}
      >
        <button
          className={styles.navButton}
          aria-label={translations.nextLabel}
          onClick={onNextClickHandler}
          data-testid={TestIds.nextButton}
        >
          <svg
            viewBox="0 0 180 310"
            className={styles.svgNav}
            data-testid={TestIds.nextIcon}
          >
            <path d="M10 10 L170 161 M170 150 L10 300" />
          </svg>
        </button>
      </div>
      <div
        className={styles.accessiblePrevButton}
        onClick={onPrevClickHandler}
        data-testid={TestIds.prev}
      >
        <button
          className={styles.navButton}
          aria-label={translations.previousLabel}
          onClick={onPrevClickHandler}
          data-testid={TestIds.prevButton}
        >
          <svg
            viewBox="0 0 180 310"
            className={styles.svgNav}
            data-testid={TestIds.prevIcon}
          >
            <path d="M170 10 L10 161 M10 150 L170 300" />
          </svg>
        </button>
      </div>
    </>
  );

  const renderNavButtons = () => {
    if (images.length > 1) {
      return popupA11yExperimentEnabled
        ? renderA11yNavButtons()
        : renderOriginalNavButtons();
    }
    return null;
  };

  return (
    <div {...rootDivProps}>
      <div
        className={classNames(styles.centeredDiv, {
          [styles.hidden]: !isImageReady,
        })}
        style={{ maxHeight: height, maxWidth: width }}
        data-testselectedimageindex={selectedImageIndex}
      >
        <Image
          {...selectedImage}
          onLoad={() => {
            setTimeout(() => setIsImageReady(true), 250);
          }}
          id={`img_${id}`}
          displayMode="fit"
        />
      </div>
      {renderImageInfo()}
      {renderCloseButton()}
      {renderNavButtons()}
    </div>
  );
};

export default TouchMediaZoomBase;
