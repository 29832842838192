import type { SkinDefinition } from '@wix/editor-elements-panel/src/adapters/types';
import { addPrefix, prefixes, regular } from './common';
import { commonContainerSkinParamsUDP } from './containerSkinParams';

export const ownDropdownSkinParamsUDP: SkinDefinition = {};
export const dropdownSkinParamsUDP: SkinDefinition = {
  ...commonContainerSkinParamsUDP,
  ...ownDropdownSkinParamsUDP,
};
export const prefixedDropdownSkinParamsUDP: SkinDefinition = addPrefix(
  dropdownSkinParamsUDP,
  prefixes.dropdown,
);

export const dropdownSkinParamsLayoutPanel: SkinDefinition = {
  anchor: {
    type: 'CSSString',
    state: regular,
    defaultValue: 'initial',
  },
  align: {
    type: 'CSSString',
    state: regular,
    defaultValue: 'initial',
  },
  'horizontal-margin': {
    type: 'SIZE',
    state: regular,
    defaultValue: 'initial',
  },
  'space-above': {
    type: 'SIZE',
    state: regular,
    defaultValue: 'initial',
  },
};

export const prefixedDropdownSkinParamsLayoutPanel = addPrefix(
  dropdownSkinParamsLayoutPanel,
  prefixes.dropdown,
);

export const dropdownSkinParams: SkinDefinition = {
  ...dropdownSkinParamsUDP,
  ...dropdownSkinParamsLayoutPanel,
};

export const prefixedDropdownSkinParams: SkinDefinition = {
  ...prefixedDropdownSkinParamsUDP,
  ...prefixedDropdownSkinParamsLayoutPanel,
};
