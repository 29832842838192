import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  getDesignPanelPreviewStateMap,
  getPartToPreviewStateMap,
} from '@wix/editor-elements-preview-utils';

import type {
  BreadcrumbsDefinition,
  BreadcrumbsMapperProps,
  BreadcrumbsPropsCarmiData,
} from '../Breadcrumbs.types';
import { props as viewerPropsMapper } from './Breadcrumbs.mapper';

const props = composeMapper<
  BreadcrumbsMapperProps,
  BreadcrumbsDefinition,
  BreadcrumbsPropsCarmiData
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState } = deps;

    const designPanelPreviewStateMap =
      getDesignPanelPreviewStateMap(compPreviewState);

    return {
      ...viewerProps,
      partToPreviewStateMap: getPartToPreviewStateMap(
        designPanelPreviewStateMap,
      ),
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
