import { generateSkinDefaults } from '@wix/editor-elements-panel';
import type { SkinDefinition } from '@wix/editor-elements-panel/src/adapters/types';

const buttonSkinParams: SkinDefinition = {
  'button-font': {
    type: 'FONT',
    state: 'regular',
  },
  'button-color': {
    type: 'TEXT_COLOR',
    state: 'regular',
  },
  'button-letter-spacing': {
    type: 'SIZE',
    state: 'regular',
  },
  'button-line-height': {
    type: 'SIZE',
    state: 'regular',
  },
  'button-text-decoration': {
    type: 'CSSString',
    state: 'regular',
  },
  'button-direction': {
    type: 'CSSString',
    state: 'regular',
  },
  'button-text-align': {
    type: 'CSSString',
    state: 'regular',
  },
  'button-text-highlight': {
    type: 'COLOR_ALPHA',
    state: 'regular',
  },
  'button-text-transform': {
    type: 'CSSString',
    state: 'regular',
  },
  'button-text-outline': {
    type: 'CSSString',
    state: 'regular',
  },
  'button-text-shadow': {
    type: 'TEXT_SHADOW',
    state: 'regular',
  },
  'button-hover-font': {
    type: 'FONT',
    state: 'hover',
  },
  'button-hover-color': {
    type: 'TEXT_COLOR',
    state: 'hover',
  },
  'button-hover-letter-spacing': {
    type: 'SIZE',
    state: 'regular',
  },
  'button-hover-line-height': {
    type: 'SIZE',
    state: 'hover',
  },
  'button-hover-text-decoration': {
    type: 'CSSString',
    state: 'hover',
  },
  'button-hover-direction': {
    type: 'CSSString',
    state: 'hover',
  },
  'button-hover-text-align': {
    type: 'CSSString',
    state: 'hover',
  },
  'button-hover-text-highlight': {
    type: 'COLOR_ALPHA',
    state: 'hover',
  },
  'button-hover-text-transform': {
    type: 'CSSString',
    state: 'hover',
  },
  'button-hover-text-outline': {
    type: 'CSSString',
    state: 'hover',
  },
  'button-hover-text-shadow': {
    type: 'TEXT_SHADOW',
    state: 'hover',
  },
  'button-disabled-font': {
    type: 'FONT',
    state: 'disabled',
  },
  'button-disabled-color': {
    type: 'TEXT_COLOR',
    state: 'disabled',
  },
  'button-disabled-line-height': {
    type: 'SIZE',
    state: 'disabled',
  },
  'button-disabled-text-decoration': {
    type: 'CSSString',
    state: 'disabled',
  },
  'button-disabled-direction': {
    type: 'CSSString',
    state: 'disabled',
  },
  'button-disabled-text-align': {
    type: 'CSSString',
    state: 'disabled',
  },
  'button-disabled-text-highlight': {
    type: 'COLOR_ALPHA',
    state: 'disabled',
  },
  'button-disabled-text-transform': {
    type: 'CSSString',
    state: 'disabled',
  },
  'button-disabled-text-outline': {
    type: 'CSSString',
    state: 'disabled',
  },
  'button-disabled-text-shadow': {
    type: 'TEXT_SHADOW',
    state: 'disabled',
  },
  'button-background': {
    type: 'BACKGROUND_FILL',
    defaultValue: 'none',
    state: 'regular',
  },
  'button-box-shadow': {
    type: 'BOX_SHADOW',
    defaultValue: '0 0px 0px rgba(0, 0, 0, 0)',
    state: 'regular',
  },
  'button-border-left': {
    type: 'BORDER_SIDE',
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'button-border-right': {
    type: 'BORDER_SIDE',
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'button-border-top': {
    type: 'BORDER_SIDE',
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'button-border-bottom': {
    type: 'BORDER_SIDE',
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'button-padding-bottom': {
    type: 'SIZE',
    defaultValue: '0px',
    state: 'regular',
  },
  'button-padding-top': {
    type: 'SIZE',
    defaultValue: '0px',
    state: 'regular',
  },
  'button-padding-left': {
    type: 'SIZE',
    defaultValue: '0px',
    state: 'regular',
  },
  'button-padding-right': {
    type: 'SIZE',
    defaultValue: '0px',
    state: 'regular',
  },
  'button-border-top-left-radius': {
    type: 'SIZE',
    defaultValue: '0',
    state: 'regular',
  },
  'button-border-top-right-radius': {
    type: 'SIZE',
    defaultValue: '0',
    state: 'regular',
  },
  'button-border-bottom-left-radius': {
    type: 'SIZE',
    defaultValue: '0',
    state: 'regular',
  },
  'button-border-bottom-right-radius': {
    type: 'SIZE',
    defaultValue: '0',
    state: 'regular',
  },
  'button-hover-background': {
    type: 'BACKGROUND_FILL',
    state: 'hover',
  },
  'button-hover-box-shadow': {
    type: 'BOX_SHADOW',
    state: 'hover',
  },
  'button-hover-border-left': {
    type: 'BORDER_SIDE',
    state: 'hover',
  },
  'button-hover-border-right': {
    type: 'BORDER_SIDE',
    state: 'hover',
  },
  'button-hover-border-top': {
    type: 'BORDER_SIDE',
    state: 'hover',
  },
  'button-hover-border-bottom': {
    type: 'BORDER_SIDE',
    state: 'hover',
  },
  'button-hover-padding-bottom': {
    type: 'SIZE',
    state: 'hover',
  },
  'button-hover-padding-top': {
    type: 'SIZE',
    state: 'hover',
  },
  'button-hover-padding-left': {
    type: 'SIZE',
    state: 'hover',
  },
  'button-hover-padding-right': {
    type: 'SIZE',
    state: 'hover',
  },
  'button-hover-border-top-left-radius': {
    type: 'SIZE',
    state: 'hover',
  },
  'button-hover-border-top-right-radius': {
    type: 'SIZE',
    state: 'hover',
  },
  'button-hover-border-bottom-left-radius': {
    type: 'SIZE',
    state: 'hover',
  },
  'button-hover-border-bottom-right-radius': {
    type: 'SIZE',
    state: 'hover',
  },
  'button-disabled-background': {
    type: 'BACKGROUND_FILL',
    state: 'disabled',
  },
  'button-disabled-box-shadow': {
    type: 'BOX_SHADOW',
    state: 'disabled',
  },
  'button-disabled-border-left': {
    type: 'BORDER_SIDE',
    state: 'disabled',
  },
  'button-disabled-border-right': {
    type: 'BORDER_SIDE',
    state: 'disabled',
  },
  'button-disabled-border-top': {
    type: 'BORDER_SIDE',
    state: 'disabled',
  },
  'button-disabled-border-bottom': {
    type: 'BORDER_SIDE',
    state: 'disabled',
  },
  'button-disabled-letter-spacing': {
    type: 'SIZE',
    state: 'regular',
  },
  'button-disabled-padding-bottom': {
    type: 'SIZE',
    state: 'disabled',
  },
  'button-disabled-padding-top': {
    type: 'SIZE',
    state: 'disabled',
  },
  'button-disabled-padding-left': {
    type: 'SIZE',
    state: 'disabled',
  },
  'button-disabled-padding-right': {
    type: 'SIZE',
    state: 'disabled',
  },
  'button-disabled-border-top-left-radius': {
    type: 'SIZE',
    state: 'disabled',
  },
  'button-disabled-border-top-right-radius': {
    type: 'SIZE',
    state: 'disabled',
  },
  'button-disabled-border-bottom-left-radius': {
    type: 'SIZE',
    state: 'disabled',
  },
  'button-disabled-border-bottom-right-radius': {
    type: 'SIZE',
    state: 'disabled',
  },
};

export const collapsibleTextSkinDefinitionMap: Record<string, SkinDefinition> =
  {
    'wixui.skins.CollapsibleText': {
      font: {
        type: 'FONT',
        defaultValue: 'font_8',
        state: 'regular',
        paramName: 'font',
      },
      color: {
        type: 'COLOR',
        defaultValue: '#000000',
        state: 'regular',
        paramName: 'color',
      },
      'letter-spacing': {
        type: 'SIZE',
        defaultValue: '0em',
        state: 'regular',
        paramName: 'letter-spacing',
      },
      'line-height': {
        type: 'SIZE',
        defaultValue: 'unset',
        state: 'regular',
        paramName: 'line-height',
      },
      'text-decoration': {
        type: 'CSSString',
        defaultValue: 'none',
        state: 'regular',
        paramName: 'text-decoration',
      },
      direction: {
        type: 'CSSString',
        defaultValue: 'ltr',
        state: 'regular',
        paramName: 'direction',
      },
      'text-align': {
        type: 'CSSString',
        defaultValue: 'revert',
        state: 'regular',
        paramName: 'text-align',
      },
      'text-highlight': {
        type: 'COLOR_ALPHA',
        defaultValue: 'none',
        state: 'regular',
        paramName: 'text-highlight',
      },
      'text-transform': {
        type: 'CSSString',
        defaultValue: 'none',
        state: 'regular',
        paramName: 'text-transform',
      },
      'text-outline': {
        type: 'CSSString',
        defaultValue:
          '1px 0px transparent, -1px 0px transparent, 0px 1px transparent, 0px -1px transparent',
        state: 'regular',
        paramName: 'text-outline',
      },
      'text-shadow': {
        type: 'TEXT_SHADOW',
        defaultValue: '0px 0px transparent',
        state: 'regular',
        paramName: 'text-shadow',
      },
      'text-font': {
        type: 'FONT',
        state: 'regular',
        paramName: 'text-font',
      },
      'text-color': {
        type: 'TEXT_COLOR',
        state: 'regular',
        paramName: 'text-color',
      },
      'text-letter-spacing': {
        type: 'SIZE',
        state: 'regular',
        paramName: 'text-letter-spacing',
      },
      'text-line-height': {
        type: 'SIZE',
        state: 'regular',
        paramName: 'text-line-height',
      },
      'text-text-decoration': {
        type: 'CSSString',
        state: 'regular',
        paramName: 'text-text-decoration',
      },
      'text-direction': {
        type: 'CSSString',
        state: 'regular',
        paramName: 'text-direction',
      },
      'text-text-align': {
        type: 'CSSString',
        state: 'regular',
        paramName: 'text-text-align',
      },
      'text-text-highlight': {
        type: 'COLOR_ALPHA',
        state: 'regular',
        paramName: 'text-text-highlight',
      },
      'text-text-transform': {
        type: 'CSSString',
        state: 'regular',
        paramName: 'text-text-transform',
      },
      'text-text-outline': {
        type: 'CSSString',
        state: 'regular',
        paramName: 'text-text-outline',
      },
      'text-text-shadow': {
        type: 'TEXT_SHADOW',
        state: 'regular',
        paramName: 'text-text-shadow',
      },
      ...buttonSkinParams,
    },
  };

export default generateSkinDefaults(collapsibleTextSkinDefinitionMap);
