import type { SkinDefinition } from '@wix/editor-elements-panel/src/adapters/types';
import {
  backgroundSkinParams,
  borderSkinParams,
  boxShadowSkinParams,
  cornerSkinParams,
  fontSkinParams,
  generateSkinDefaults,
  layoutSkinParams,
  withDefaultValues,
  withParts,
  withStates,
} from '@wix/editor-elements-panel';
import { udpStateNames } from './editor/panels/constants';

const skinParams = {
  ...fontSkinParams,
  ...layoutSkinParams,
  ...backgroundSkinParams,
  ...borderSkinParams,
  ...cornerSkinParams,
  ...boxShadowSkinParams,
};

export const withDefaults = withDefaultValues(skinParams);

export const skinDefinition = {
  /**
   * stateful skinParams should be without default values
   * otherwise "Revert to Regular state" will not work
   */
  ...withParts(
    ['breadcrumb-content'],
    withStates(udpStateNames, skinParams, 'regular'),
  ),
  ...withParts(['breadcrumb-content', 'separator'], withDefaults),
  ...withDefaults,
};

export const skinDefinitionMap: Record<string, SkinDefinition> = {
  'wixui.skins.Breadcrumbs': skinDefinition,
};

export default generateSkinDefaults(skinDefinitionMap);
