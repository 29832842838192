import {
  cssPropertyToDefaultValueMap as defaults,
  generateSkinDefaults,
  REGULAR_STATE,
} from '@wix/editor-elements-panel';
import type { SkinDefinition } from '@wix/editor-elements-panel/src/adapters/types';

// At the moment we need to keep this in sync with packages/editor-elements-design-panel-params/src/designPanelsConfigs/params.json
export const selectableContainerInputSkinDefinitionMap: Record<
  string,
  SkinDefinition
> = {
  'wixui.skins.SelectableContainerInput': {
    font: {
      type: 'FONT',
      defaultValue: defaults.font,
      state: REGULAR_STATE.id,
    },
    color: {
      type: 'CSS_COLOR',
      defaultValue: '#000000',
      state: REGULAR_STATE.id,
    },
    'letter-spacing': {
      type: 'SIZE',
      defaultValue: defaults['letter-spacing'],
      state: REGULAR_STATE.id,
    },
    'line-height': {
      type: 'SIZE',
      defaultValue: defaults['line-height'],
      state: REGULAR_STATE.id,
    },
    'text-decoration': {
      type: 'CSSString',
      defaultValue: defaults['text-decoration'],
      state: REGULAR_STATE.id,
    },
    direction: {
      type: 'CSSString',
      defaultValue: 'revert',
      state: REGULAR_STATE.id,
    },
    'text-align': {
      type: 'CSSString',
      defaultValue: defaults['text-align'],
      state: REGULAR_STATE.id,
    },
    'text-highlight': {
      type: 'CSS_COLOR',
      defaultValue: 'none',
      state: REGULAR_STATE.id,
    },
    'text-transform': {
      type: 'CSSString',
      defaultValue: defaults['text-transform'],
      state: REGULAR_STATE.id,
    },
    'text-outline': {
      type: 'CSSString',
      defaultValue: defaults.outline,
      state: REGULAR_STATE.id,
    },
    'text-shadow': {
      type: 'TEXT_SHADOW',
      defaultValue: defaults['text-shadow'],
      state: REGULAR_STATE.id,
    },
    background: {
      type: 'BACKGROUND_FILL',
      defaultValue: defaults.background,
      state: REGULAR_STATE.id,
    },
    'border-left': {
      type: 'BORDER_SIDE',
      defaultValue: defaults['border-left'],
      state: REGULAR_STATE.id,
    },
    'border-right': {
      type: 'BORDER_SIDE',
      defaultValue: defaults['border-right'],
      state: REGULAR_STATE.id,
    },
    'border-top': {
      type: 'BORDER_SIDE',
      defaultValue: defaults['border-top'],
      state: REGULAR_STATE.id,
    },
    'border-bottom': {
      type: 'BORDER_SIDE',
      defaultValue: defaults['border-bottom'],
      state: REGULAR_STATE.id,
    },
    'border-top-left-radius': {
      type: 'SIZE',
      defaultValue: defaults['border-top-left-radius'],
      state: REGULAR_STATE.id,
    },
    'border-top-right-radius': {
      type: 'SIZE',
      defaultValue: defaults['border-top-right-radius'],
      state: REGULAR_STATE.id,
    },
    'border-bottom-left-radius': {
      type: 'SIZE',
      defaultValue: defaults['border-bottom-left-radius'],
      state: REGULAR_STATE.id,
    },
    'border-bottom-right-radius': {
      type: 'SIZE',
      defaultValue: defaults['border-bottom-right-radius'],
      state: REGULAR_STATE.id,
    },
    'box-shadow': {
      type: 'BOX_SHADOW',
      defaultValue: defaults['box-shadow'],
      state: REGULAR_STATE.id,
    },
    'button-size': {
      type: 'SIZE',
      defaultValue: '16px',
      state: REGULAR_STATE.id,
    },
  },
};

export default generateSkinDefaults(selectableContainerInputSkinDefinitionMap);
