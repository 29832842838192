import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';
import type { ResponsiveContainerProps } from '@wix/thunderbolt-components';
import type { IHamburgerMenuContainerDefinition } from '../documentManagement/HamburgerMenuContainer.definition';
import type { IHamburgerMenuContainerProps } from '../HamburgerMenuContainer.props';

export type IHamburgerMenuContainerCarmiProps = {
  containerProps?: ResponsiveContainerProps;
  containerRootClassName?: string;
};
export type HamburgerMenuContainerCss = {
  'min-height'?: string;
};

export const props = withCompInfo<
  IHamburgerMenuContainerProps,
  IHamburgerMenuContainerDefinition,
  IHamburgerMenuContainerCarmiProps
>()(
  ['compProps', 'hasResponsiveLayout', 'compStylableClass'],
  ({ compProps, hasResponsiveLayout, compStylableClass }, carmiData) => {
    return {
      ...compProps,
      ...carmiData,
      hasResponsiveLayout,
      stylableClassName: compStylableClass,
    };
  },
);

const css = withCompInfo<
  HamburgerMenuContainerCss,
  IHamburgerMenuContainerDefinition
>()(['hasResponsiveLayout'], ({ hasResponsiveLayout }) => {
  const notResponsiveCss = {
    'min-height': 'calc(100vh - var(--wix-ads-height))',
    'margin-bottom': '0',
  };
  return hasResponsiveLayout ? {} : notResponsiveCss;
});

export default createComponentMapperModel({
  props,
  css,
});
