import React, { useMemo } from 'react';
import classNames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import semanticClassNames from '../FastGallery.semanticClassNames';
import type { IFastGalleryCompProps } from '../FastGallery.types';
import styles from './style/FastGallery.scss';
import { Gallery } from '@wix/fast-gallery-components';
import type {
  EventData,
  EventsListenerFunction,
} from '@wix/fast-gallery-library';
import { GALLERY_EVENTS } from '@wix/fast-gallery-library';
import MeshContainer from '@wix/thunderbolt-elements/components/MeshContainer';
import ResponsiveContainer from '@wix/thunderbolt-elements/components/ResponsiveContainer';
import {
  convertWixSDKItemsToContentItems,
  convertOrganizeMediaPanelItemsToContentItems,
} from './FastGallery.galleryUtils';

const FastGallery: React.FC<IFastGalleryCompProps> = props => {
  const {
    id,
    customClassNames = [],
    wixSDKItems,
    items = [],
    onItemClicked = () => {},
    onGetMoreItems = () => {},
    children,
    containerProps,
    containerRootClassName,
    hasResponsiveLayout,
    meshProps,
    itemClickAction,
  } = props;

  const galleryItems = useMemo(
    () =>
      wixSDKItems?.length > 0
        ? convertWixSDKItemsToContentItems(wixSDKItems)
        : convertOrganizeMediaPanelItemsToContentItems(items),
    [wixSDKItems, items],
  );

  const eventsListener: EventsListenerFunction = (
    eventName: GALLERY_EVENTS,
    eventData: EventData,
  ) => {
    switch (eventName) {
      case GALLERY_EVENTS.ON_ITEM_CLICKED:
        onItemClicked({
          itemIndex: eventData.idx,
          type: eventData.type,
        });
        break;
      case GALLERY_EVENTS.NEED_MORE_ITEMS:
        onGetMoreItems({
          from: eventData.idx,
        });
        break;
      default:
        break;
    }
  };

  return (
    <div
      id={id}
      className={classNames(
        'root',
        containerRootClassName,
        formatClassNames(semanticClassNames.root, ...customClassNames),
        styles.root,
      )}
    >
      <Gallery
        dataList={galleryItems.map(item => {
          return {
            ...item,
            url: item.mediaUrl,
          };
        })}
        options={{ itemClickAction }}
        eventsListener={eventsListener}
        selectors={{
          container: classNames(
            'container-reset',
            'container',
            formatClassNames(semanticClassNames.container),
          ),
          item: classNames(
            'item-reset',
            'item',
            formatClassNames(semanticClassNames.item),
            styles.item,
          ),
          image: classNames(
            'image',
            formatClassNames(semanticClassNames.image),
            styles.image,
          ),
          info: classNames(
            'info',
            formatClassNames(semanticClassNames.info),
            styles.info,
          ),
          overlay: classNames('overlay', styles.overlay),
        }}
      />
      {hasResponsiveLayout ? (
        <ResponsiveContainer {...containerProps}>
          {children}
        </ResponsiveContainer>
      ) : (
        <MeshContainer {...meshProps} id={id}>
          {children}
        </MeshContainer>
      )}
    </div>
  );
};

export default FastGallery;
