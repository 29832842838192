import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  getDesignPanelPreviewStateMap,
  getPartToPreviewStateMap,
} from '@wix/editor-elements-preview-utils';

import type {
  SubmenuDefinition,
  SubmenuCarmiProps,
  SubmenuPreviewMapperProps,
} from '../types';
import { props as viewerPropsMapper } from './Submenu.mapper';

const props = composeMapper<
  SubmenuPreviewMapperProps,
  SubmenuDefinition,
  SubmenuCarmiProps
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState } = deps;

    const designPanelPreviewStateMap =
      getDesignPanelPreviewStateMap(compPreviewState);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      partToPreviewStateMap: getPartToPreviewStateMap(
        designPanelPreviewStateMap,
      ),
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
