import {
  cssPropertyToDefaultValueMap,
  generateDefaultStyleProperties,
  generateSkinDefaults,
} from '@wix/editor-elements-panel';
import type { SkinDefinition } from '@wix/editor-elements-panel/src/adapters/types';
import { itemSkinParams } from './skinParams/itemSkinParams';
import { containerSkinParams } from './skinParams/containerSkinParams';
import { regular } from './skinParams/common';
import { scrollButtonSkinParams } from './skinParams/scrollButtonSkinParams';
import { prefixedDropdownSkinParams } from './skinParams/dropdownSkinParams';
import { prefixedDropdownMenuSkinParams } from './skinParams/dropdownMenuSkinParams';

export const skinDefinition: Record<string, SkinDefinition> = {
  'wixui.skins.Menu': {
    ...containerSkinParams,
    ...itemSkinParams,
    ...scrollButtonSkinParams,
    ...prefixedDropdownSkinParams,
    ...prefixedDropdownMenuSkinParams,
    'display-mode': {
      type: 'CSSString',
      defaultValue: 'navbar',
      state: regular,
    },
    'spacing-between-label-and-dropdown-icon': {
      type: 'SIZE',
      defaultValue: '0px',
      state: regular,
    },
    'menu-items-main-axis-gap': {
      type: 'SIZE',
      defaultValue: '0px',
      state: regular,
    },
    'menu-items-cross-axis-gap': {
      type: 'SIZE',
      defaultValue: '0px',
      state: regular,
    },
    // TODO figure out correct types, default values and correct naming for these vvv
    orientation: {
      type: 'CSSString',
      defaultValue: 'horizontal',
      state: regular,
    },
    overflow: {
      type: 'CSSString',
      defaultValue: cssPropertyToDefaultValueMap.overflow,
      state: regular,
    },
    addDividers: {
      type: 'CSSString',
      defaultValue: 'false',
      state: regular,
    },
    'container-direction': {
      type: 'CSSString',
      defaultValue: 'ltr',
      state: regular,
    },
    'container-align': {
      type: 'CSSString',
      defaultValue: 'center',
      state: regular,
    },
    'menu-items-justification': {
      type: 'CSSString',
      defaultValue: 'none',
      state: regular,
    },
  },
};

export const defaultStyleProperties =
  generateDefaultStyleProperties(skinDefinition);

export default generateSkinDefaults(skinDefinition);
