import React, { useEffect, useRef, useState, useMemo } from 'react';
import classNames from 'clsx';
import Link from '@wix/thunderbolt-elements/components/Link';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import type { IMenuItemProps } from '../../../Menu.types';
import { MenuItemContext } from '../../../../../common/menu/MenuItemContext';
import { createEventListeners, createSDKAction } from './utils';
import { showDropdown } from './showDropdown';
import classes from './style/MenuItem.scss';
import { testIds } from '../../constants';
import { isCurrentItem } from '../../../../../common/menu/getCurrentMenuItem';
import menuSemanticClassNames from '../../../Menu.semanticClassNames';
import shmSemanticClassNames from '../../../../StylableHorizontalMenu/StylableHorizontalMenu.semanticClassNames';
import DropdownIcon from '../../assets/dropdownIcon.svg';
import { VirtualDropdown } from '../VirtualDropdown';
import type { MenuItemProps } from '@wix/editor-elements-definitions';

const itemWrapperClassName = classNames(
  classes.itemWrapper,
  formatClassNames(shmSemanticClassNames.menuItemWrapper),
);

const itemLabelClassName = classNames(
  classes.label,
  formatClassNames(shmSemanticClassNames.menuItemLabel),
  formatClassNames(menuSemanticClassNames.itemLabel),
);

function HorizontalDropdown(props: {
  onEscKeyDown: () => void;
  item: MenuItemProps;
  children?: React.ReactNode | undefined;
}) {
  return (
    <div className={classes.horizontalDropdownDisplayWrapper}>
      <MenuItemContext.Provider
        value={{ onEscKeyDown: props.onEscKeyDown, item: props.item }}
      >
        {props.children ?? (
          <VirtualDropdown item={props.item} menuOrientation="horizontal" />
        )}
      </MenuItemContext.Provider>
    </div>
  );
}

function VerticalDropdown(props: {
  hovered: boolean;
  onEscKeyDown: () => void;
  item: MenuItemProps & { forceHovered?: boolean } & {
    children?: React.ReactNode | undefined;
  };
}) {
  return (
    <div
      className={classNames(
        classes.verticalDropdownDisplayWrapper,
        props.hovered && classes.expandedDropdown,
      )}
    >
      <MenuItemContext.Provider
        value={{ onEscKeyDown: props.onEscKeyDown, item: props.item }}
      >
        <VirtualDropdown item={props.item} menuOrientation="vertical" />
      </MenuItemContext.Provider>
    </div>
  );
}

export const MenuItem = (props: IMenuItemProps) => {
  const {
    item,
    currentItem,
    onItemClick,
    onItemDblClick,
    onItemMouseIn,
    onItemMouseOut,
    previewState,
    translations,
  } = props;
  const { label, link, children, forceHovered = false } = item;
  const [isHovered, setIsHovered] = useState(forceHovered);
  const hasSubItems = !!item.items?.length;
  const hasMegaMenuContainer = !!children;
  const hasDropdownMenu = hasMegaMenuContainer || hasSubItems;
  const chevronButtonRef = useRef<HTMLButtonElement>(null);

  const itemRef = useRef<HTMLLIElement>(null);
  const eventListeners = useMemo(
    () => createEventListeners(setIsHovered, itemRef),
    [],
  );

  useEffect(() => {
    if (!isHovered || !itemRef.current) {
      return;
    }
    return showDropdown(itemRef.current);
  }, [isHovered]);

  useEffect(() => {
    setIsHovered(!!forceHovered);
  }, [forceHovered]);

  const isCurrentPage = isCurrentItem(item, currentItem);

  const handleEscKeyDown = () => {
    chevronButtonRef.current?.focus();

    setIsHovered(false);
  };

  return (
    <li
      className={classes.listItem}
      ref={itemRef}
      {...eventListeners}
      data-testid={testIds.menuItem}
      data-item-depth="0" // For scrolling, to know how much items on depth=0
    >
      <div className={itemWrapperClassName}>
        <div
          className={classNames(
            classes.labelContainer,
            isCurrentPage ? classes.selected : '',
            hasDropdownMenu &&
              formatClassNames(shmSemanticClassNames.subMenuTitle),
            formatClassNames(menuSemanticClassNames.item),
          )}
          data-preview={previewState}
          data-testid={testIds.itemLabel}
        >
          <Link
            {...link}
            className={itemLabelClassName}
            activateByKey="Enter"
            onClick={createSDKAction(item, isCurrentPage, onItemClick)}
            onMouseEnter={createSDKAction(item, isCurrentPage, onItemMouseIn)}
            onMouseLeave={createSDKAction(item, isCurrentPage, onItemMouseOut)}
            onDoubleClick={createSDKAction(item, isCurrentPage, onItemDblClick)}
            {...(isCurrentPage && {
              ariaCurrent: 'page',
            })}
          >
            {label}
          </Link>

          {hasDropdownMenu && (
            <button
              aria-label={translations.dropdownButtonAriaLabel}
              ref={chevronButtonRef}
              className={classNames(
                classes.dropdownToggleButton,
                !hasSubItems && classes.noDropdownItems,
              )}
              onClick={() => setIsHovered(currentHovered => !currentHovered)}
              aria-expanded={isHovered}
              aria-haspopup={true}
            >
              <DropdownIcon
                className={classNames(
                  isHovered && classes.expandedDropdownIcon,
                  formatClassNames(menuSemanticClassNames.itemIcon),
                )}
              />
            </button>
          )}
        </div>
        {hasDropdownMenu && (
          <HorizontalDropdown
            onEscKeyDown={handleEscKeyDown}
            item={item}
            children={children}
          />
        )}
      </div>
      {hasSubItems && (
        <VerticalDropdown
          hovered={isHovered}
          onEscKeyDown={handleEscKeyDown}
          item={item}
        />
      )}
    </li>
  );
};
