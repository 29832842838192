import * as React from 'react';
import {
  formatClassNames,
  sanitizeHTML,
  getDataAttributes,
  getTabIndexAttribute,
} from '@wix/editor-elements-common-utils';
import { useResizeObserver } from '@wix/thunderbolt-elements/src/providers/useResizeObserver';
import classNames from 'clsx';
import type { ICollapsibleTextCompProps } from '../../../CollapsibleText.types';
import { ExpandModeValues, TestIds } from '../../../constants';
import { TagValue } from '../../../../../common/panel-sections/seoAndA11y/consts';
import semanticClassNames from '../../../CollapsibleText.semanticClassNames';
import { isSafariVersionLess } from '../../utils/isSafariVersionLess';
import styles from './CollapsibleText.scss';
import { ExpandControl } from './ExpandControl/ExpandControl';

/* line clamp doens't work properly with block items in Safari version less 17 */
const safariGoodVersion = 17;

const isRichText = (text: string) => /<\/?[a-z][\s\S]*>/i.test(text);

const noop = () => {};

const getIsExpandRedundant = (
  textRef: React.RefObject<HTMLDivElement>,
  maxLines: number,
) => {
  const txtElement = textRef.current;
  if (txtElement) {
    const lineHeight = parseFloat(getComputedStyle(txtElement).lineHeight);
    const maxHeight = Math.ceil(lineHeight * maxLines);
    return txtElement.scrollHeight <= maxHeight;
  }

  return false;
};

const CollapsibleText: React.FC<ICollapsibleTextCompProps> = props => {
  const {
    id,
    text,
    className,
    customClassNames = [],
    direction,
    showExpandControl,
    expandMode,
    ellipsis,
    wrappingStyle,
    isExpanded,
    maxLines,
    tag: TagName = TagValue.P,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
  } = props;
  const wrapperId = `wrapper-layer-${id}`;
  const textRef = React.useRef<HTMLDivElement>(null);
  const [isExpandRedundant, setIsExpandRedundant] = React.useState(false);
  const [shouldShowExpandControl, setShouldShowExpandControl] =
    React.useState(false);

  const textFormat = React.useMemo(() => {
    return isRichText(text) ? 'rtf' : 'plain';
  }, [text]);

  useResizeObserver({
    ref: textRef,
    callback: () =>
      setIsExpandRedundant(getIsExpandRedundant(textRef, maxLines)),
  });

  React.useEffect(() => {
    setIsExpandRedundant(getIsExpandRedundant(textRef, maxLines));
  }, [text, maxLines]);

  React.useEffect(() => {
    const shouldShow =
      ellipsis &&
      ((expandMode === ExpandModeValues.Link && showExpandControl) ||
        (expandMode === ExpandModeValues.Expand &&
          (isExpanded || !isExpandRedundant)));

    setShouldShowExpandControl(shouldShow);
  }, [ellipsis, expandMode, showExpandControl, isExpanded, isExpandRedundant]);

  const textClassName = classNames(
    styles.text,
    formatClassNames(semanticClassNames.text),
    {
      [styles.collapsed]: ellipsis && !isExpanded,
      [styles.singleLine]: wrappingStyle === 'single-line',
      [styles.multiLine]: wrappingStyle === 'multi-line',
      [styles.plain]: textFormat === 'plain',
      [styles.withSafariListsFix]:
        wrappingStyle === 'multi-line' &&
        isSafariVersionLess(safariGoodVersion),
    },
  );

  // Needed for rich-text support
  const Text: React.FC<{ _text: string }> = ({ _text }) =>
    React.createElement(TagName, {
      dangerouslySetInnerHTML: {
        __html: sanitizeHTML(_text.trim()),
      },
      className: textClassName,
      ref: textRef,
    });

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      {...getTabIndexAttribute(props.a11y)}
      className={className}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        id={wrapperId}
        dir={direction}
        className={classNames(
          styles.root,
          formatClassNames(semanticClassNames.root, ...customClassNames),
        )}
        data-testid={TestIds.viewer.root}
      >
        <div className={styles['text-wrapper']}>
          <Text _text={text} />
        </div>
        {shouldShowExpandControl && (
          <ExpandControl {...props} wrapperId={wrapperId} />
        )}
      </div>
    </div>
  );
};

export default CollapsibleText;
