import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getLanguage } from '../common/utils';
import type {
  WFacebookCommentDefinition,
  WFacebookCommentStateRefs,
  WFacebookCommentMapperProps,
} from '../WFacebookComment.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  consentPolicyPropsDeps,
  withConsentPolicyProps,
} from '../../ConsentPolicyWrapper/viewer/utils';

export const consetPolicyKeys = {
  componentNameKey: 'CookieConsent_Dataset_Component_WFacebookComment',
  componentNameDefault: 'Facebook Comments',
};

export const props = withCompInfo<
  WFacebookCommentMapperProps,
  WFacebookCommentDefinition
>()(
  [
    'compData',
    'compProps',
    'isMobileView',
    'language',
    'externalBaseUrl',
    ...consentPolicyPropsDeps,
  ],
  compInfo => {
    const {
      compData: { urlFormat, isHttpsEnabled },
      compProps,
      isMobileView,
      language,
      externalBaseUrl,
    } = compInfo;
    const originalProps = {
      ...compProps,
      width: isMobileView ? 320 : compProps.width,
      language: getLanguage(language),
      urlFormat,
      externalBaseUrl,
      isHttpsEnabled,
    };
    return withConsentPolicyProps(
      compInfo,
      originalProps,
      consetPolicyKeys.componentNameKey,
      consetPolicyKeys.componentNameDefault,
    );
  },
);

export const css = withCompInfo<any, WFacebookCommentDefinition>()(
  ['hasResponsiveLayout', 'compLayout', 'isOneDocMigrated'],
  ({ hasResponsiveLayout, compLayout, isOneDocMigrated }) => {
    return hasResponsiveLayout || isOneDocMigrated
      ? {}
      : {
          height: 'auto',
          'min-height': `${compLayout.height}px`,
        };
  },
);

export const stateRefs = withStateRefsValues<keyof WFacebookCommentStateRefs>([
  'pageId',
  'relativeUrl',
  'currentUrlWithQueryParams',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, css, stateRefs, uiType });
