import type { ImageProps } from '@wix/thunderbolt-components';
import { STATIC_MEDIA_URL, getData } from '@wix/image-kit';
import type { ResponsiveImageProps } from './Image';

export const getResponsiveImageProps = (
  imageData: Omit<ImageProps, 'alt'>,
  targetWidth: number,
  targetHeight: number,
): ResponsiveImageProps => {
  const {
    displayMode,
    uri,
    width,
    height,
    crop,
    focalPoint,
    quality,
    upscaleMethod,
    hasAnimation,
    alignType,
    name,
  } = imageData;

  const { srcset, css } = getData(
    displayMode,
    { id: uri, width, height, name, crop, focalPoint },
    {
      width: targetWidth,
      height: targetHeight,
      alignment: alignType,
    },
    {
      focalPoint,
      name,
      quality: quality?.quality,
      upscaleMethod,
      hasAnimation,
      useSrcset: true,
    },
  );

  const srcsetString =
    srcset?.dpr
      ?.map((s: string) => (/^[a-z]+:/.test(s) ? s : `${STATIC_MEDIA_URL}${s}`))
      .join(', ') || '';

  return {
    srcset: srcsetString,
    css,
  };
};
