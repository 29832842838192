export const DataType = 'CustomMenuDataRef';
export const MenuRef = '#CUSTOM_MAIN_MENU';
export const ViewerType = 'wixui.Menu';
import type {
  Direction,
  JustifyAlignment,
} from '@wix/editor-elements-common-utils';

export const MenuOrientation = {
  Horizontal: 'horizontal',
  Vertical: 'vertical',
} as const;
export const MenuJustification = {
  Justify: 'justify',
  None: 'none',
} as const;
export const VerticalDropdownDisplay = {
  AlwaysOpen: 'alwaysOpen',
  ExpandCollapse: 'expandCollapse',
} as const;
export const MenuOverflow = {
  Scroll: 'Scroll',
  Wrap: 'Wrap',
} as const;
export const MenuDisplayMode = {
  Navbar: 'navbar',
  Hamburger: 'hamburger',
};
export const MenuDirection: Record<string, Direction> = {
  RTL: 'rtl',
  LTR: 'ltr',
} as const;
export const MenuAlignment: Record<string, JustifyAlignment> = {
  Start: 'start',
  Center: 'center',
  End: 'end',
  Justify: 'justify',
} as const;
export const ItemTextAlignment = {
  Left: 'left',
  Center: 'center',
  Justify: 'justify',
  Right: 'right',
} as const;
export const ComponentMetaData = {
  displayName: 'Menu',
  componentType: ViewerType,
  nickName: 'menu',
  skinName: 'wixui.skins.Menu',
};
export const DropdownAnchor = {
  menuItem: 'menuItem',
  menuStretched: 'menuStretched',
  menuCustomWidth: 'menuCustomWidth',
  screen: 'screen',
} as const;

export const TranslationKeys = {
  MenuDefinition: 'component-editors.wixui.Menu_DisplayName',
  gfppManageLabel: 'menu_gfpp_manage_label',
  layoutPanel: {
    vertical: {
      AlwaysOpen: 'menu_layout_dropdown_tab_item_display_open',
      ExpandCollapse: 'menu_layout_dropdown_tab_item_display_collapsed',
      VerticalDropdownDisplay: 'menu_layout_dropdown_tab_item_display_label',
    },
    horizontal: {
      Scroll: 'menu_layout_overflow_scroll',
      Wrap: 'menu_layout_overflow_wrap',
      ShowScrollIcon: 'menu_layout_scroll_toggle_label',
      ShowDropdownIcon: 'menu_layout_dropdown_toggle_label',
      OverflowItems: 'menu_layout_overflow_label',
    },
    dropdownWidth: 'menu_layout_dropdown_tab_width_label',
    dropdownOptions: {
      HugContent: 'menu_layout_dropdown_tab_width_content',
      FitToMenu: 'menu_layout_dropdown_tab_width_menu',
      Stretch: 'menu_layout_dropdown_tab_width_stretch',
    },
    MenuLayout: 'menu_layout_header_label',
    Menu: 'menu_layout_tab_menu_label',
    Dropdown: 'menu_layout_tab_dropdown_label',
    Orientation: 'menu_layout_display_label',
    Horizontal: 'menu_layout_display_horizontal',
    Vertical: 'menu_layout_display_vertical',
    Spacing: 'menu_layout_spacing_section_title',
    SpaceBetweenItems: 'menu_layout_spacing_label',
    Padding: 'menu_layout_padding_section_title',
    ItemPadding: 'menu_layout_padding_label',
    MenuPadding: 'menu_layout_menu_padding_label',
    Add_dividers: 'menu_layout_dividers_toggle',
    Space_between_items_divider: 'menu_layout_spacing_divider_label',
    Space_between_text_icon: 'menu_layout_spacing_icon_label',
    ScrollButtonPadding: 'menu_layout_scroll_button_padding_label',
  },
  designPanel: {
    title: 'menu_design_header_label',
    itemPart: 'menu_design_part_selection_items',
    containerPart: 'menu_design_part_selection_container',
    scrollButtonPart: 'menu_design_part_selection_scroll',
  },
};

// TODO: replace with real help ids
export const helpIds = {
  layoutPanel: 'to-do',
  designPanel: 'to-do',
};

export const MenuParts = {
  Menu: 1,
  Dropdown: 2,
};

export const DataHooks = {
  layoutPanel: {
    vertical: {
      verticalDropdownDisplay: 'vertical-dropdown-display',
    },
    horizontal: {
      overflowThumbnails: 'overflow-thumbnails',
      showScrollButton: 'show-scroll-button',
      showDropDownIcon: 'show-dropdown-icon',
    },
    orientationThumbnails: 'orientation-thumbnails',
    addDividers: 'add-dividers',
    horizontalSpacingBetweenItems: 'horizontal-spacing-between-items',
    spaceBetweenItemsDivider: 'space-between-items-divider',
    verticalSpacingBetweenItems: 'vertical-spacing-between-items',
    spacingBetweenItems: 'spacing-between-items',
    spacingBetweenTextAndDropdownIcon: 'spacing-between-text-and-dropdown-icon',
    paddingItem: 'padding-item',
    verticalPaddingItem: `vertical-padding-item`,
    horizontalPaddingItem: 'horizontal-padding-item',
    paddingMenu: 'padding-menu',
    verticalPaddingMenu: 'vertical-padding-menu',
    horizontalPaddingMenu: 'horizontal-padding-menu',
    scrollButtonPadding: 'scroll-button-padding',
    verticalScrollButtonPadding: 'vertical-scroll-button-padding',
    horizontalScrollButtonPadding: 'horizontal-scroll-button-padding',
    direction: 'direction',
    alignment: 'alignment',
  },
};
