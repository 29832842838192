import type { IAccessibilityPropSDKProps } from '@wix/editor-elements-corvid-utils';

export const useAccessibilityAttributes = (
  attributes: IAccessibilityPropSDKProps,
) => {
  const {
    role,
    tabIndex,
    tabindex,
    screenReader,
    ariaAttributes = {},
  } = attributes;
  const prefixedAriaAttributes = Object.entries(ariaAttributes).reduce(
    (prev, [key, value]) => {
      return { ...prev, [`aria-${key}`.toLowerCase()]: value };
    },
    {},
  );
  return {
    role,
    tabIndex: tabIndex ?? tabindex,
    screenReader,
    ariaAttributes: prefixedAriaAttributes,
  };
};
