import { generateSkinDefaults } from '@wix/editor-elements-panel';
import type { legacyStylePropertiesTypes } from '@wix/editor-elements-panel/src/adapters/constants';
import type { SkinDefinition } from '@wix/editor-elements-panel/src/adapters/types';

const rootParams = {
  'border-left': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'border-right': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'border-top': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'border-bottom': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  background: {
    type: 'BACKGROUND_FILL' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '#ffffff',
    state: 'regular',
  },
  'box-shadow': {
    type: 'BOX_SHADOW' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '',
    state: 'regular',
  },
  'border-top-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'border-top-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'border-bottom-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'border-bottom-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'padding-left': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px',
    state: 'regular',
  },
  'padding-right': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px',
    state: 'regular',
  },
  'padding-top': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px',
    state: 'regular',
  },
  'padding-bottom': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px',
    state: 'regular',
  },
};

const rootHoverParams = {
  'hover-border-left': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'hover-border-right': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'hover-border-top': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'hover-border-bottom': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'hover-background': {
    type: 'BACKGROUND_FILL' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'hover-box-shadow': {
    type: 'BOX_SHADOW' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'hover-border-top-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'hover-border-top-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'hover-border-bottom-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'hover-border-bottom-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
};

const itemParams = {
  'item-border-left': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'item-border-right': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'item-border-top': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'item-border-bottom': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'item-background': {
    type: 'BACKGROUND_FILL' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '#ffffff',
    state: 'regular',
  },
  'item-box-shadow': {
    type: 'BOX_SHADOW' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px',
    state: 'regular',
  },
  'item-border-top-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'item-border-top-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'item-border-bottom-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'item-border-bottom-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
};

const itemHoverParams = {
  'item-hover-border-left': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'item-hover-border-right': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'item-hover-border-top': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'item-hover-border-bottom': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'item-hover-background': {
    type: 'BACKGROUND_FILL' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'item-hover-hover-background': {
    type: 'BACKGROUND_FILL' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'item-hover-box-shadow': {
    type: 'BOX_SHADOW' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'item-hover-border-top-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'item-hover-border-top-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'item-hover-border-bottom-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'item-hover-border-bottom-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
};

const infoParams = {
  'info-text-display': {
    type: 'CSSString' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: 'block',
    state: 'regular',
  },
  'info-text-align': {
    type: 'CSSString' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: 'revert',
    state: 'regular',
  },
  'info-text-highlight': {
    type: 'CSS_COLOR' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: 'none',
    state: 'regular',
  },
  'info-text-transform': {
    type: 'CSSString' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: 'none',
    state: 'regular',
  },
  'info-text-outline': {
    type: 'CSSString' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue:
      '1px 0px transparent, -1px 0px transparent, 0px 1px transparent, 0px -1px transparent',
    state: 'regular',
  },
  'info-text-shadow': {
    type: 'TEXT_SHADOW' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '',
    state: 'regular',
  },
  'info-font': {
    type: 'FONT' as (typeof legacyStylePropertiesTypes)[number],
    state: 'regular',
    defaultValue: 'font_6',
  },
  'info-color': {
    type: 'CSS_COLOR' as (typeof legacyStylePropertiesTypes)[number],
    state: 'regular',
    defaultValue: 'rgba(0,0,0)',
  },
  'info-letter-spacing': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'regular',
    defaultValue: '1em',
  },
  'info-line-height': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'regular',
    defaultValue: '1.4em',
  },
  'info-text-decoration': {
    type: 'CSSString' as (typeof legacyStylePropertiesTypes)[number],
    state: 'regular',
    defaultValue: 'none',
  },
  'info-direction': {
    type: 'CSSString' as (typeof legacyStylePropertiesTypes)[number],
    state: 'regular',
    defaultValue: 'ltr',
  },
  'info-padding-bottom': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px',
    state: 'regular',
  },
  'info-padding-top': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px',
    state: 'regular',
  },
  'info-padding-left': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px',
    state: 'regular',
  },
  'info-padding-right': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px',
    state: 'regular',
  },
};

const infoHoverParams = {
  'info-hover-text-display': {
    type: 'CSSString' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-text-align': {
    type: 'CSSString' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-text-highlight': {
    type: 'CSS_COLOR' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-text-transform': {
    type: 'CSSString' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-text-outline': {
    type: 'CSSString' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-text-shadow': {
    type: 'TEXT_SHADOW' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-font': {
    type: 'FONT' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-color': {
    type: 'CSS_COLOR' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-letter-spacing': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-line-height': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-text-decoration': {
    type: 'CSSString' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-direction': {
    type: 'CSSString' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-padding-bottom': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-padding-top': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-padding-left': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'info-hover-padding-right': {
    type: 'SIZE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
};

const overlayParams = {
  'overlay-border-left': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'overlay-border-right': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'overlay-border-top': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'overlay-border-bottom': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'overlay-background': {
    type: 'BACKGROUND_FILL' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '#ffffff',
    state: 'regular',
  },
  'overlay-border-top-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'overlay-border-top-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'overlay-border-bottom-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'overlay-border-bottom-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
};

const overlayHoverParams = {
  'overlay-hover-border-left': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'overlay-hover-border-right': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'overlay-hover-border-top': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'overlay-hover-border-bottom': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'overlay-hover-background': {
    type: 'BACKGROUND_FILL' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'overlay-hover-border-top-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'overlay-hover-border-top-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'overlay-hover-border-bottom-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'overlay-hover-border-bottom-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
};

const imageParams = {
  'image-background': {
    type: 'BACKGROUND_FILL' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '#ffffff',
    state: 'regular',
  },
  'image-border-left': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'image-border-right': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'image-border-top': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'image-border-bottom': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0px none #000000',
    state: 'regular',
  },
  'image-border-top-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'image-border-top-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'image-border-bottom-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'image-border-bottom-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '0',
    state: 'regular',
  },
  'image-box-shadow': {
    type: 'BOX_SHADOW' as (typeof legacyStylePropertiesTypes)[number],
    defaultValue: '',
    state: 'regular',
  },
};

const imageHoverParams = {
  'image-hover-background': {
    type: 'BACKGROUND_FILL' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'image-hover-border-left': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'image-hover-border-right': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'image-hover-border-top': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'image-hover-border-bottom': {
    type: 'BORDER_SIDE' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'image-hover-border-top-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'image-hover-border-top-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'image-hover-border-bottom-left-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'image-hover-border-bottom-right-radius': {
    type: 'BORDER' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
  'image-hover-box-shadow': {
    type: 'BOX_SHADOW' as (typeof legacyStylePropertiesTypes)[number],
    state: 'hover',
  },
};

export const fastGallerySkinDefinition: Record<string, SkinDefinition> = {
  'wixui.skins.FastGallery': {
    ...rootParams,
    ...rootHoverParams,
    ...itemParams,
    ...itemHoverParams,
    ...infoParams,
    ...infoHoverParams,
    ...overlayParams,
    ...overlayHoverParams,
    ...imageParams,
    ...imageHoverParams,
  },
} as const;

export default generateSkinDefaults(fastGallerySkinDefinition);
