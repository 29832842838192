
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "RangeSlider1928817616",
      {"classes":{"root":"RangeSlider1928817616__root","markLabel":"Slider351545463__markLabel","sliderHandleTooltip":"Slider351545463__sliderHandleTooltip","sliderHandle":"Slider351545463__sliderHandle","markLine":"Slider351545463__markLine","sliderThumb":"Slider351545463__sliderThumb","container":"RangeSlider1928817616__container","label":"RangeSlider1928817616__label","baseSlider":"RangeSlider1928817616__baseSlider","slider-rail":"wsr-slider-rail","slider-track":"wsr-slider-track"},"vars":{"MinThumbPos":"--MinThumbPos","MaxThumbPos":"--MaxThumbPos","defaultThumbHeight":"--defaultThumbHeight","label-left-padding":"--RangeSlider1928817616-label-left-padding","label-right-padding":"--RangeSlider1928817616-label-right-padding","label-bottom-padding":"--RangeSlider1928817616-label-bottom-padding","rail-percentage":"--RangeSlider1928817616-rail-percentage","thumbHeight":"--RangeSlider1928817616-thumbHeight","thumbWidth":"--RangeSlider1928817616-thumbWidth","halfThumbWidth":"--RangeSlider1928817616-halfThumbWidth","minThumbDeviation":"--RangeSlider1928817616-minThumbDeviation","maxThumbDeviation":"--RangeSlider1928817616-maxThumbDeviation","maxThumbOppositeDeviation":"--RangeSlider1928817616-maxThumbOppositeDeviation"},"stVars":{"sliderMarkDotSize":"3px","sliderMarkLineHeight":"10px","sliderMarkMarginSpace":"7px"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  