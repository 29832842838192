import React, { forwardRef, useMemo } from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type {
  PreviewWrapperProps,
  IComponentPreviewWrapper,
} from '@wix/editor-elements-types/thunderboltPreview';
import type {
  IMenuProps,
  IMenuImperativeActions,
  IMenuPreviewWrapperProps,
} from '../Menu.types';
import { useEditSlotId } from '../../../common/menu/megaMenuParent';
import { forceOpenIfNeeded } from './previewWrapperUtils';

import { useUpdateDropdownPosition } from './previewWrapperUtils/updateDropdownPosition';
import { parsePreview } from './utils/parsePreview';

type IMenuPreviewProps = IMenuProps & {
  forceState?: { selectionSelector: string };
};

function withComponentPreview(
  ViewerComponent: React.ComponentType<IMenuProps>,
): IComponentPreviewWrapper<IMenuPreviewProps, IMenuPreviewWrapperProps> {
  return forwardRef<
    IMenuImperativeActions,
    PreviewWrapperProps<IMenuPreviewProps, IMenuPreviewWrapperProps>
  >((props, ref) => {
    const { items, previewWrapperProps, ...viewerProps } = props;
    const {
      compPreviewState,
      designPanelPreviewStateMap,
      layoutPanelPreviewStateMap,
    } = previewWrapperProps || {};
    const editSlotId =
      useEditSlotId(compPreviewState) ||
      parsePreview({
        designPanelPreviewStateMap,
        layoutPanelPreviewStateMap,
        items,
      });

    const shownDropdownId = editSlotId && props.slots?.[editSlotId]?.props.id;
    useUpdateDropdownPosition(props.id, shownDropdownId);

    const itemsEnhanced = useMemo(
      () =>
        forceOpenIfNeeded({
          items,
          editSlotId,
        }),
      [items, editSlotId],
    );

    return <ViewerComponent {...viewerProps} items={itemsEnhanced} ref={ref} />;
  });
}

export default (ViewerComponent: React.ComponentType<IMenuPreviewProps>) =>
  createComponentPreviewEntry<IMenuPreviewProps, IMenuPreviewWrapperProps>(
    withComponentPreview(ViewerComponent),
  );
