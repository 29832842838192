import * as React from 'react';
import semanticClassNames from '../DynamicRepeater.semanticClassNames';
import { formatClassNames } from '@wix/editor-elements-common-utils';

// Dynamic repeater is rendered using the regular Repeater's viewer logic
// So this component should never render, however it's not deleted for build related issues
const DynamicRepeater: React.FC<any> = props => {
  const {
    id,
    customClassNames = [],
    label,
    alignment,
    value,
    onChange,
  } = props;

  return (
    <label
      id={id}
      className={formatClassNames(semanticClassNames.root, ...customClassNames)}
      dir={alignment}
    >
      {label}:{' '}
      <input
        className={formatClassNames(semanticClassNames.repeaterItem)}
        type="color"
        value={value}
        onChange={onChange}
      />
    </label>
  );
};

export default DynamicRepeater;
