import { useCallback } from 'react';
import { useCompStyleElementObserver } from '@wix/thunderbolt-elements/providers/useCompStyleElementObserver';
import {
  dataSelectors,
  showDropdown,
} from '../components/MenuItem/showDropdown';

export const useUpdateDropdownPosition = (
  id: string,
  shownDropdownId: string | undefined,
) => {
  const updateDropdownPosition = useCallback(() => {
    const itemEl = document
      .getElementById(id)
      ?.querySelector(`[${dataSelectors.selected}]`);

    if (itemEl) {
      showDropdown(itemEl as HTMLElement, false);
    }
  }, [id]);

  useCompStyleElementObserver({
    compIds: [id, shownDropdownId].filter(Boolean) as Array<string>,
    shouldObserve: !!shownDropdownId,
    callback: updateDropdownPosition,
  });
};
