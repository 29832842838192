import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';

const structure = withCompInfo()(
  ['structure'],
  ({ structure: compStructure }) => {
    return {
      [compStructure.id]: {
        ...compStructure,
        componentType: 'Repeater',
      },
    };
  },
);

export default createComponentMapperModel({ structure });
