import {
  withCompPreviewInfo,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import type { CompPreviewInfoDependency } from '@wix/editor-elements-types/thunderboltPreview';

import {
  css as mediaContainerCssMapper,
  props as mediaContainerPropsMapper,
} from '@wix/thunderbolt-elements/components/MediaContainer/previewMapper';

import type {
  StripColumnsContainerCss,
  StripColumnsContainerDefinition,
} from '../StripColumnsContainer.types';

import { css as viewerCssMapper } from './StripColumnsContainer.mapper';

const mediaContainerCssDeps = Object.keys(
  mediaContainerCssMapper.deps,
) as Array<CompPreviewInfoDependency>;
const viewerCssMapperDeps = Object.keys(
  viewerCssMapper.deps,
) as Array<CompPreviewInfoDependency>;

const css = withCompPreviewInfo<
  StripColumnsContainerCss,
  StripColumnsContainerDefinition,
  StripColumnsContainerCss
>()(
  ['compProps', ...mediaContainerCssDeps, ...viewerCssMapperDeps],
  (deps, carmiData) => {
    const { compProps } = deps;

    const mediaContainerCss = mediaContainerCssMapper.resolver(deps, carmiData);
    const viewerCss = viewerCssMapper.resolver(deps, carmiData);

    const width = !compProps.fullWidth ? { width: 'var(--site-width)' } : {};
    const minHeight = compProps.fullHeight
      ? { 'min-height': 'calc(100vh - var(--wix-ads-height))' }
      : {};

    return {
      ...carmiData,
      ...mediaContainerCss,
      ...width,
      ...minHeight,
      '--items-direction': viewerCss['--items-direction'],
    };
  },
);

export default createComponentPreviewMapperModel({
  props: mediaContainerPropsMapper,
  css,
});
