import {
  generateSkinDefaults,
  backgroundSkinParams,
  borderSkinParams,
  cornerSkinParams,
  boxShadowSkinParams,
} from '@wix/editor-elements-panel';
import type { SkinDefinition } from '@wix/editor-elements-panel/dist/adapters/types';

export const SelectableContainerSkinDefinition: Record<string, SkinDefinition> =
  {
    'wixui.skins.SelectableContainer': {
      ...backgroundSkinParams,
      ...borderSkinParams,
      ...cornerSkinParams,
      ...boxShadowSkinParams,
    },
  };
export default generateSkinDefaults(SelectableContainerSkinDefinition);
