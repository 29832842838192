import SelectableContainerInputCommon from '../../SelectableContainerInput.common';
import type { SelectableContainerInputComponentProps } from '../../../types';
import React from 'react';
import {
  classes,
  st,
} from '../../style/SelectableContainerInput.component.st.css';
import { useSelectableContainerContext } from '../../../../SelectableContainer/SelectableContainer.context';

const Default: React.FC<SelectableContainerInputComponentProps> = props => {
  const { selected, inputType } = useSelectableContainerContext();
  const { stylableClassName, selectedPreview = false } = props;
  const isSelected = selected || selectedPreview;

  const extendedProps = {
    ...props,
    classes: {
      ...classes,
      root: st(
        classes.root,
        {
          selected: isSelected,
          inputType,
        },
        stylableClassName,
      ),
    },
  };
  return <SelectableContainerInputCommon {...extendedProps} />;
};

export default Default;
