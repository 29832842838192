import { classes as stylableClasses } from '../../style/SelectableContainer.component.st.css';
import SelectableContainerCommon from '../../SelectableContainer';
import React from 'react';
import type {
  ISelectableContainerImperativeActions,
  ISelectableContainerProps,
} from '../../../types';
import classNames from 'clsx';

const SelectableContainerStylableSkin: React.ForwardRefRenderFunction<
  ISelectableContainerImperativeActions,
  ISelectableContainerProps
> = (props, ref) => {
  const { stylableClassName } = props;
  return (
    <SelectableContainerCommon
      {...props}
      ref={ref}
      classes={{
        ...stylableClasses,
        root: classNames(stylableClasses.root, stylableClassName),
      }}
    />
  );
};
export default React.forwardRef(SelectableContainerStylableSkin);
