import {
  generateDefaultStyleProperties,
  generateSkinDefaults,
} from '@wix/editor-elements-panel';
import { Skins } from './constants';
import { dropdownSkinParams } from '../Menu/skinParams/dropdownSkinParams';

export const megaMenuContainerItemSkinDefinition = {
  [Skins.dropdownContainerSkin]: dropdownSkinParams,
} as const;

export const defaultAttachedMMCIStyleProperties =
  generateDefaultStyleProperties(megaMenuContainerItemSkinDefinition, true);

export const defaultDetachedMMCIStyleProperties =
  generateDefaultStyleProperties(megaMenuContainerItemSkinDefinition);

export default generateSkinDefaults(megaMenuContainerItemSkinDefinition);
