import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import type { Direction } from '@wix/editor-elements-common-utils';
import {
  getDataAttributes,
  useDomDirection,
} from '@wix/editor-elements-common-utils';
import type {
  BreadcrumbsProps,
  BreadcrumbProps,
  Breadcrumb as IBreadcrumb,
  Separator as ISeparator,
  ClassNames,
} from '../../Breadcrumbs.types';
import {
  canRenderEllipsis,
  getHeadIndex,
  getTailIndex,
} from '../../Breadcrumbs.utils';

type Separator = ISeparator | 'smaller-than' | 'reverse-slash';

const separatorsHash: Record<Separator, string> = {
  'greater-than': '>',
  'smaller-than': '<',
  slash: '/',
  'reverse-slash': '\\',
};

const getDisplayedSeparator = (
  separator: BreadcrumbsProps['separator'],
  direction?: Direction,
): Separator => {
  if (separator === 'greater-than') {
    return separator;
  }
  return direction === 'rtl' ? 'reverse-slash' : 'slash';
};

const Icon: React.FC<{ src: string; className: string }> = ({
  src,
  className,
}) => {
  return React.createElement('span', {
    dangerouslySetInnerHTML: {
      __html: src,
    },
    className,
  });
};

const SeparatorElement: React.FC<{
  separator: Separator;
  className: string;
}> = ({ separator, className }) => (
  <span aria-hidden="true" className={className}>
    {separatorsHash[separator] || separator}
  </span>
);

const Breadcrumb: React.FC<
  Omit<BreadcrumbProps, 'separator'> & {
    separator: Separator;
    onClick?: () => void;
    title?: string;
    isEllipsis?: boolean;
    dataPreview?: string;
  }
> = ({
  icon,
  label,
  link: url,
  separator,
  isCurrent,
  onClick,
  title = label,
  isEllipsis,
  classNames,
  dataPreview,
}) => {
  const BreadcrumbTag = url ? 'a' : 'button';

  const tagClassName = isCurrent
    ? classNames.breadcrumbContentCurrent
    : classNames.breadcrumbContent;

  const liClassName = icon ? classNames.breadcrumbIcon : classNames.breadcrumb;

  return (
    <li className={liClassName}>
      <BreadcrumbTag
        aria-haspopup={true}
        aria-label={title}
        {...(isCurrent && { 'aria-current': 'page' })}
        {...(isEllipsis && { 'data-breadcrumb-ellipsis': 'true' })}
        className={tagClassName}
        onClick={onClick}
        {...(url && {
          href: url,
          target: '_self',
        })}
        data-breadcrumb-focus
        data-preview={dataPreview}
      >
        <span className={classNames.label}>
          {icon ? <Icon src={icon} className={classNames.icon} /> : label}
        </span>
      </BreadcrumbTag>
      <SeparatorElement
        separator={separator}
        className={classNames.separator}
      />
    </li>
  );
};

const PreviousPage: React.FC<{
  breadcrumbs: BreadcrumbsProps['breadcrumbs'];
  classNames: ClassNames;
}> = ({ breadcrumbs, classNames }) => {
  const previousPage = React.useMemo(() => {
    const currentPageIndex = breadcrumbs.findIndex(item => item.isCurrent);

    for (let i = currentPageIndex - 1; i >= 0; i--) {
      if (breadcrumbs[i].link) {
        return breadcrumbs[i];
      }
    }

    return breadcrumbs[0];
  }, [breadcrumbs]);

  return previousPage ? (
    <Breadcrumb
      {...previousPage}
      separator="smaller-than"
      classNames={classNames}
    />
  ) : null;
};

type BreadcrumbsContentProps = Pick<
  BreadcrumbsProps,
  | 'itemsAfterCollapse'
  | 'itemsBeforeCollapse'
  | 'breadcrumbs'
  | 'showHomePage'
  | 'showCurrentPage'
  | 'classNames'
> & {
  displayedSeparator: Separator;
  showEllipsis: boolean;
  onEllipsisClick: () => void;
  dataPreview?: string;
};

const BreadcrumbsContent: React.FC<BreadcrumbsContentProps> = props => {
  const {
    breadcrumbs,
    showEllipsis,
    itemsBeforeCollapse,
    itemsAfterCollapse,
    displayedSeparator,
    onEllipsisClick,
    showCurrentPage,
    showHomePage,
    classNames,
    dataPreview,
  } = props;

  const sharedProps = {
    classNames,
    separator: displayedSeparator,
    dataPreview,
  };

  const getBreadcrumb = (item: IBreadcrumb, key: number) => (
    <Breadcrumb key={key} {...item} {...sharedProps} />
  );

  if (showEllipsis && canRenderEllipsis(props)) {
    const headIndex = getHeadIndex(itemsBeforeCollapse, showHomePage);
    const tailIndex = getTailIndex(itemsAfterCollapse, showCurrentPage);

    const head = breadcrumbs.slice(0, headIndex).map(getBreadcrumb);
    const tail = tailIndex
      ? breadcrumbs.slice(-tailIndex).map(getBreadcrumb)
      : [];

    return (
      <>
        {head}
        <Breadcrumb
          key="ellipsis"
          onClick={onEllipsisClick}
          label="..."
          title="Reveal the full breadcrumbs list"
          isEllipsis
          {...sharedProps}
        />
        {tail}
      </>
    );
  } else {
    return <>{breadcrumbs.map(getBreadcrumb)}</>;
  }
};

const focusElementAfterExpand = (list: HTMLOListElement | null, i: number) =>
  setTimeout(
    () =>
      (
        list?.querySelectorAll('[data-breadcrumb-focus]')[i] as HTMLElement
      )?.focus(),
    100,
  );

// https://www.w3.org/TR/wai-aria-practices-1.1/examples/breadcrumb/index.html
const Breadcrumbs: React.ForwardRefRenderFunction<
  HTMLDivElement,
  BreadcrumbsProps
> = (props, ref) => {
  const {
    id,
    breadcrumbs,
    itemsBeforeCollapse,
    itemsAfterCollapse,
    separator,
    shouldWrap,
    showOnlyPreviousPageOnMobile,
    onMouseEnter,
    onMouseLeave,
    showCurrentPage,
    showHomePage,
    onEllipsisVisibilityChange,
    classNames,
    partToPreviewStateMap,
  } = props;

  const listRef = useRef<HTMLOListElement>(null);
  const { direction, directionRef } = useDomDirection();
  const displayedSeparator = getDisplayedSeparator(separator, direction);
  const [showEllipsis, setShowEllipsis] = useState<boolean>(!shouldWrap);

  useEffect(() => {
    setShowEllipsis(!shouldWrap);
  }, [shouldWrap]);

  const handleEllipsisClick = () => {
    onEllipsisVisibilityChange(false);
    setShowEllipsis(false);
    focusElementAfterExpand(listRef.current, itemsBeforeCollapse);
  };

  return (
    <div
      id={id}
      className={classNames.root}
      {...getDataAttributes(props)}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <nav
        ref={directionRef}
        aria-label="breadcrumbs"
        className={classNames.nav}
      >
        <ol className={classNames.ol} ref={listRef}>
          {showOnlyPreviousPageOnMobile ? (
            <PreviousPage breadcrumbs={breadcrumbs} classNames={classNames} />
          ) : (
            <BreadcrumbsContent
              showEllipsis={showEllipsis}
              itemsBeforeCollapse={itemsBeforeCollapse}
              itemsAfterCollapse={itemsAfterCollapse}
              breadcrumbs={breadcrumbs}
              displayedSeparator={displayedSeparator}
              onEllipsisClick={handleEllipsisClick}
              showCurrentPage={showCurrentPage}
              showHomePage={showHomePage}
              classNames={classNames}
              dataPreview={partToPreviewStateMap?.['breadcrumb-content']}
            />
          )}
        </ol>
      </nav>
    </div>
  );
};

export default React.forwardRef(Breadcrumbs);
