
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IProductSpecificationProps,
  IProductSpecificationMapperProps,
  IProductSpecificationControllerProps,
  IProductSpecificationStateValues,
} from '../../../components/ProductSpecification/ProductSpecification.props';

export default withCompController<
  IProductSpecificationMapperProps,
  IProductSpecificationControllerProps,
  IProductSpecificationProps,
  IProductSpecificationStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
