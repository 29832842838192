import { HOVER_STATE, REGULAR_STATE } from '@wix/editor-elements-panel';

export enum Selectors {
  Root = '.root',
  Separator = '.root::separator',
  Breadcrumb = '.root::breadcrumb',
  BreadcrumbContent = '.root::breadcrumb-content',
}

export const HelpIds = {
  NotificationBannerLinkIdStudio: '1ae7dd6e-9cf1-4c6d-bbcb-43cf1acafe1b',
  NotificationBannerLinkIdClassic: 'df82f805-2544-44d5-b701-58551d0deabd',
};

export const TranslationKeys = {
  settings: {
    PanelHeader: 'Breadcrumbs_Settings_Header_Label',
    Description: 'Breadcrumbs_Settings_Description',
    HomepageType: 'Breadcrumbs_Layout_Display_Homepage',
    TextHomepage: 'Breadcrumbs_Layout_Display_Homepage_Text_Label',
    IconHomepage: 'Breadcrumbs_Layout_Display_Homepage_Icon_Label',
    InfoLink: 'Breadcrumbs_Settings_Link_Learn_More',
    ItemsVisibilityTitle: 'Breadcrumbs_Settings_Text',
    HomepageAndCurrent: 'Breadcrumbs_Settings_Homepage_Location_Label',
    HideHomepage: 'Breadcrumbs_Settings_Homepage_Label',
    HideCurrentLocation: 'Breadcrumbs_Settings_Location_Label',
    HomeIcon: 'Breadcrumbs_Layout_Home_Icon_Label',
    ReplaceHomeIcon: 'Breadcrumbs_Layout_Home_Icon_Replace_Label',
    OpenSeoText: 'Breadcrumbs_Settings_Parent_Page_Rich_Text',
    OpenSeoButton: 'Breadcrumbs_Settings_Parent_Page_Button',
    NotificationBannerNotification:
      'Breadcrumbs_Settings_Parent_Page_Notification',
    NotificationBannerLinkLabel: 'Breadcrumbs_Settings_Parent_Page_Link',
    NotificationBannerNotificationTPA:
      'Breadcrumbs_Settings_Notification_TPA_Pages',
    NotificationBannerLinkLabelLearnMore:
      'Breadcrumbs_Settings_Notification_TPA_Pages_Link',
    NotificationBannerNotificationClassic:
      'Breadcrumbs_Settings_Notification_Classic',
    NotificationBannerLinkLabelClassic:
      'Breadcrumbs_Settings_Notification_Classic_Link',
  },
  layout: {
    PanelHeader: 'Breadcrumbs_Layout_Header_Label',
    GreaterThan: 'Breadcrumbs_Layout_Separator_Icons_GreaterThan_Label',
    Slash: 'Breadcrumbs_Layout_Separator_Icons_Slash_Label',
    BreadcrumbsWrapping: 'Breadcrumbs_Layout_Overflow_Description',
    Wrap: 'Breadcrumbs_Layout_Overflow_Wrap_Label',
    NoWrap: 'Breadcrumbs_Layout_Overflow_1Line_Label',
    SeparatorToggle: 'Breadcrumbs_Layout_Separator_Toggle_Label',
    SeparatorThumbnails: 'Breadcrumbs_Layout_Separator_Icons_Label',
    SpacingSectionTitle: 'Breadcrumbs_Layout_Spacing_Section_Title',
    VerticalSpacingTitle: 'Breadcrumbs_Layout_Spacing_Vertical_Label',
    HorizontalSpacingTitle: 'Breadcrumbs_Layout_Spacing_Horizontal_Label',
    AlignmentSectionTitle: 'Breadcrumbs_Layout_Alignment_Section_Title',
    AlignmentTitle: 'Breadcrumbs_Layout_Alignment_Items_Label',
    DirectionTitle: 'Breadcrumbs_Layout_Direction_Label',
    LeftToRightDirection: 'Breadcrumbs_Layout_Direction_Left_Right_Label',
    RightToLeftDirection: 'Breadcrumbs_Layout_Direction_Right_Left_Label',
    OverflowTooltip: 'Breadcrumbs_Layout_Overflow_Tooltip',
    OverflowLabel: 'Breadcrumbs_Layout_Overflow_Collapse_Text_Label',
    CollapseTooltip: 'Breadcrumbs_Layout_Overflow_Collapse_Text_Tooltip',
    FromStart: 'Breadcrumbs_Layout_Overflow_Items_Expand_Start',
    FromEnd: 'Breadcrumbs_Layout_Overflow_Items_Expand_End',
    alignmentLeftTooltip: 'Breadcrumbs_Layout_Alignment_Tooltip_Left',
    alignmentCenterTooltip: 'Breadcrumbs_Layout_Alignment_Tooltip_Center',
    alignmentRightTooltip: 'Breadcrumbs_Layout_Alignment_Tooltip_Right',
  },
} as const;

export const MobileTranslationKeys = {
  settings: {
    PanelHeader: 'Mobile_Breadcrumbs_Settings_Header',
    Description: 'Mobile_Breadcrumbs_Settings_Description',
    TextHomepage: 'Mobile_Breadcrumbs_Layout_Display_Text',
    IconHomepage: 'Mobile_Breadcrumbs_Layout_Display_Icon',
    ItemsVisibilityTitle: 'Mobile_Breadcrumbs_Settings_Display_Label',
    HomepageAndCurrent: 'Mobile_Breadcrumbs_Settings_Display_Homepage_Current',
    HideHomepage: 'Mobile_Breadcrumbs_Settings_Display_Homepage',
    HideCurrentLocation: 'Mobile_Breadcrumbs_Settings_Display_Location',
    OnlyPreviousPage: 'Breadcrumbs_Settings_Location_Previous_Page_Label',
  },
  layout: {
    PanelTitle: 'Mobile_Breadcrumbs_Layout_Header',
    HomepageType: 'Mobile_Breadcrumbs_Layout_Display_Homepage',
    TextHomepage: 'Mobile_Breadcrumbs_Layout_Display_Text',
    IconHomepage: 'Mobile_Breadcrumbs_Layout_Display_Icon',
    SpacingSectionTitle: 'Mobile_Breadcrumbs_Layout_Item_Spacing',
    VerticalSpacingTitle: 'Mobile_Breadcrumbs_Layout_Spacing_Vertical',
    HorizontalSpacingTitle: 'Mobile_Breadcrumbs_Layout_Spacing_Horizontal',
  },
} as const;

export const DataHooks = {
  settings: {
    VisibleItems: 'default_state',
    HomepageType: 'display_option',
    IconSelector: 'home_icon',
  },
  layout: {
    ItemsDontFit: 'items_layout',
    ToggleSeparator: 'show_seperators',
    Separator: 'separator',
    VerticalSpacing: 'vertical_spacing',
    HorizontalSpacing: 'horizontal_spacing',
    ItemsAlignment: 'item_alignment',
    Direction: 'item_direction',
    ItemsBeforeCollapseInput: 'num_of_items_before',
    ItemsAfterCollapseInput: 'num_of_items_after',
  },
} as const;

export const visibleItemsUpdates = {
  homeAndCurrent: {
    showHomePage: true,
    showCurrentPage: true,
    showOnlyPreviousPageOnMobile: false,
  },
  home: {
    showHomePage: true,
    showCurrentPage: false,
    showOnlyPreviousPageOnMobile: false,
  },
  current: {
    showHomePage: false,
    showCurrentPage: true,
    showOnlyPreviousPageOnMobile: false,
  },
  onlyPreviousPage: {
    showOnlyPreviousPageOnMobile: true,
  },
} as const;

export const udpStates = [
  REGULAR_STATE,
  HOVER_STATE,
  {
    id: 'current',
    label: 'Mobile_Breadcrumbs_Settings_Display_Homepage_Current',
  },
];

export const udpStateNames = udpStates.map(({ id }) => id);
