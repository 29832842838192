import type { ItemContentData } from '@wix/fast-gallery-library';
import { cssStringToBuilderReturnType } from '@wix/fast-gallery-library';
import type {
  IWixSDKItemLink,
  OrganizeMediaPanelItems,
  WixSDKItems,
} from '../FastGallery.types';

export const convertCustomCssToSelectorToCss = (customCss: string) => {
  if (!customCss) {
    return {};
  }
  try {
    const customCssObj = cssStringToBuilderReturnType(
      customCss.replaceAll('\n', ''),
    ).cssObject;

    const splitSelectorsCssObject: any = {};
    for (const [selectors, properties] of Object.entries(customCssObj)) {
      selectors.split(',').forEach(selector => {
        const trimmedSelector = selector.trim();
        splitSelectorsCssObject[trimmedSelector] = properties;
      });
    }
    return splitSelectorsCssObject;
  } catch {
    return {};
  }
};

export const formatWixCodeLinkToObject = (link?: IWixSDKItemLink) => {
  if (link && typeof link === 'object') {
    return {
      url: link?.url || '',
      target: link?.target || '_blank',
    };
  }
  if (typeof link === 'string') {
    return {
      url: link,
      target: '_blank',
    };
  }

  return {
    target: '_blank',
    url: '',
  };
};

export function extractItemSrcData(
  src: string = '',
): { uri: string; width: number; height: number } | null {
  const regex =
    /wix:image:\/\/v1\/([^/]+)\/[^#]+#originWidth=(\d+)&originHeight=(\d+)/;
  const match = src.match(regex);

  if (match) {
    const [, uri, width, height] = match;
    return {
      uri,
      width: parseInt(width, 10),
      height: parseInt(height, 10),
    };
  } else {
    return null;
  }
}

export const convertOrganizeMediaPanelItemsToContentItems = (
  items: Array<OrganizeMediaPanelItems>,
): Array<ItemContentData> => {
  return items.map((item, index) => ({
    id: item?.id || `item-${index}`,
    index: index.toString(),
    mediaUrl: 'https://static.wixstatic.com/media/' + item.uri,
    metaData: {
      width: item?.width || 400,
      height: item?.height || 400,
      title: item?.title || '',
      description: `image ${item?.title} description` || '',
      alt: item?.alt || '',
      link: {
        url: '',
        target: '_blank',
      },
    },
    type: item?.type.toLowerCase() || 'image',
  }));
};

export const convertWixSDKItemsToContentItems = (
  items: WixSDKItems,
): Array<ItemContentData> => {
  return items?.map((item, index) => {
    const itemLink = formatWixCodeLinkToObject(item?.link);
    const srcData = extractItemSrcData(item.src);
    const itemAlt = 'alt' in item ? item?.alt : '';
    return {
      id: `item-${index}`,
      index: index.toString(),
      mediaUrl: 'https://static.wixstatic.com/media/' + srcData?.uri,
      metaData: {
        width: srcData?.width || 400,
        height: srcData?.height || 400,
        title: item?.title || '',
        description: item?.description || '',
        alt: itemAlt || '',
        link: itemLink,
      },
      // TODO: fix focalPoint type on library
      focalPoint: {
        // @ts-expect-error
        x: item.settings?.focalPoint?.at(0) || 0.5,
        // @ts-expect-error
        y: item?.settings?.focalPoint?.at(1) || 0.5,
      },
      type: item?.type?.toLowerCase(),
    };
  });
};
