import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type { Overflow } from '@wix/editor-elements-common-utils';
import type {
  IMenuCarmiData,
  IMenuCssVars,
  IMenuDefinition,
  IMenuMapperProps,
  IMenuStateRefs,
  MenuDisplayModeType,
  MenuJustificationType,
  MenuOrientationType,
  MenuVerticalDropdownDisplayType,
} from '../Menu.types';
import {
  displayModeToCssVarsMap,
  orientationToCssVarsMap,
  dropdownDisplayTypeToCssVarMap,
  verticalOverflowCssVars,
  horizontalOverflowToCssVarsMap,
  justificationToCssVarsMap,
} from './utils/MapperUtils';
import type { IMenuSDKData } from '@wix/editor-elements-corvid-utils/src/props-factories/menuUtils';
import {
  MenuOverflow,
  MenuOrientation,
  VerticalDropdownDisplay,
  MenuDirection,
} from '../constants';

export const props = withCompInfo<
  IMenuMapperProps,
  IMenuDefinition,
  IMenuCarmiData
>()(['compData', 'translate'], ({ compData, translate }, carmiData) => {
  return {
    ...carmiData,
    ...compData,
    translations: {
      dropdownButtonAriaLabel:
        translate('ariaLabels', 'Menu_AriaLabel_Dropdown_Button') ||
        'Button to toggle menu',
    },
  };
});

export const css = withCompInfo<IMenuCssVars, IMenuDefinition>()(
  ['styleProperties'],
  ({ styleProperties }) => {
    const {
      orientation = MenuOrientation.Horizontal,
      'vertical-dropdown-display':
        verticalDropdownDisplay = VerticalDropdownDisplay.AlwaysOpen,
      overflow = MenuOverflow.Scroll,
      'horizontal-item-icon-display': showDropDownIcon = 'none',
      addDividers = false,
      'display-mode': displayMode,
      'scroll-button-icon-display': scrollButtonDisplay = 'unset',
      'menu-items-justification': justification = 'none',
      'item-text-align': itemTextAlign = 'center',
      'container-direction': menuDirection = MenuDirection.LTR,
    } = styleProperties;

    const isVerticalOrientation = orientation === MenuOrientation.Vertical;
    return {
      ...displayModeToCssVarsMap[displayMode as MenuDisplayModeType],
      ...orientationToCssVarsMap[orientation as MenuOrientationType],
      ...justificationToCssVarsMap[justification as MenuJustificationType],

      ...(isVerticalOrientation
        ? {
            ...verticalOverflowCssVars,
            ...dropdownDisplayTypeToCssVarMap[
              verticalDropdownDisplay as MenuVerticalDropdownDisplayType
            ],
          }
        : horizontalOverflowToCssVarsMap[overflow as Overflow]),
      '--dropdown-arrow-display':
        showDropDownIcon !== 'none' &&
        orientation === MenuOrientation.Horizontal
          ? 'block'
          : 'none',
      '--divider-display': addDividers ? 'block' : 'none',
      '--scroll-button-icon-display':
        overflow === MenuOverflow.Scroll ? scrollButtonDisplay : 'none',
      '--item-alignment':
        itemTextAlign === 'justify' ? 'space-between' : itemTextAlign,
      '--scroll-button-direction':
        menuDirection === MenuDirection.RTL ? 'scaleX(-1)' : 'scaleX(1)',
    };
  },
);

export const sdkData = withCompInfo<IMenuSDKData, IMenuDefinition>()(
  ['pagesMap'],
  ({ pagesMap }) => {
    return {
      pageList: pagesMap,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof IMenuStateRefs>([
  'currentUrl',
]);

export default createComponentMapperModel({ css, props, stateRefs, sdkData });
