import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';
import type { ResponsiveContainerProps } from '@wix/thunderbolt-components';
import type { ImageFit, ItemInfoPosition } from '@wix/fast-gallery-library';
import {
  CustomGridScrollDirection,
  buildCustomGridStyles,
  buildSliderStyles,
  buildMasonryStyles,
  buildSlideshowStyles,
  stringToPatternObject,
  buildItemStyles,
  resetContainerCustomStyleSheet,
  resetItemCustomStyleSheet,
} from '@wix/fast-gallery-library';
import _ from 'lodash';
import {
  defaultFastGalleryProps,
  FAST_GALLERY_MAPPERS_SELECTORS,
} from '../constants';
import { convertCustomCssToSelectorToCss } from './FastGallery.galleryUtils';

export type IFastGalleryContainerCarmiProps = {
  containerProps?: ResponsiveContainerProps;
  containerRootClassName?: string;
};

export const props = withCompInfo<any, any, IFastGalleryContainerCarmiProps>()(
  ['compData', 'hasResponsiveLayout'],
  ({ compData, hasResponsiveLayout }, carmiData) => {
    const { items = [], itemClickAction, wixSDKItems } = compData;
    return {
      ...carmiData,
      items,
      wixSDKItems,
      hasResponsiveLayout,
      itemClickAction,
    };
  },
);

export const selectorToCss = withCompInfo<any, any>()(
  ['styleProperties'],
  ({ styleProperties }) => {
    const {
      layout = defaultFastGalleryProps.layout,
      item_info_position = defaultFastGalleryProps.item_info_position,
      item_image_fit = defaultFastGalleryProps.item_image_fit,
      enableTitle = defaultFastGalleryProps.enableTitle, // boolean values can be string in live site
      item_elements_alignment = defaultFastGalleryProps.item_elements_alignment,
      customCss = '',
    } = styleProperties;

    const resetContainerStyles =
      resetContainerCustomStyleSheet('.container-reset').cssObject;
    const resetItemStyles = resetItemCustomStyleSheet('.item-reset').cssObject;

    const selectorToCssCustomCss = convertCustomCssToSelectorToCss(customCss);

    let layoutStyles: Record<string, any> = {};

    const itemStyles = buildItemStyles({
      infoPosition: item_info_position as ItemInfoPosition,
      imageFit: item_image_fit as ImageFit,
      enableTitle: enableTitle === true || enableTitle === 'true',
      contentItemsAlignment: item_elements_alignment as any,
    }).cssObject;

    switch (layout) {
      case 'Custom Grid':
        const {
          custom_grid_pattern = '[[1,1,3],[2,1]]',
          custom_grid_opposite_pattern = '300px 400px',
          custom_grid_flip = false,
          custom_grid_mode = CustomGridScrollDirection.vertical,
          custom_grid_horizontal_gap = 0,
          custom_grid_vertical_gap = 0,
        } = styleProperties;

        const customGridStyles = buildCustomGridStyles({
          selectors: FAST_GALLERY_MAPPERS_SELECTORS,
          pattern: stringToPatternObject(custom_grid_pattern),
          oppositePattern: custom_grid_opposite_pattern,
          mode: custom_grid_mode as CustomGridScrollDirection,
          // @ts-expect-error
          flip: custom_grid_flip === 'true' || custom_grid_flip === true,
          horizontalGap: +custom_grid_horizontal_gap,
          verticalGap: +custom_grid_vertical_gap,
        }).cssObject;

        layoutStyles = customGridStyles;
        break;

      case 'Slider':
        const { slider_gap = 0, slider_item_width = 300 } = styleProperties;
        const sliderStyles = buildSliderStyles({
          selectors: FAST_GALLERY_MAPPERS_SELECTORS,
          sliderGap: +slider_gap,
          sliderItemWidth: +slider_item_width,
        }).cssObject;

        layoutStyles = sliderStyles;
        break;

      case 'Masonry':
        const {
          masonry_density = 10,
          masonry_horizontal_gaps = 0,
          masonry_vertical_gaps = 0,
        } = styleProperties;
        const masonryStyles = buildMasonryStyles({
          selectors: FAST_GALLERY_MAPPERS_SELECTORS,
          masonryDensity: +masonry_density,
          masonryHorizontalGap: +masonry_horizontal_gaps,
          masonryVerticalGap: +masonry_vertical_gaps,
        }).cssObject;

        layoutStyles = masonryStyles;
        break;

      case 'Slideshow':
        const { slideshow_horizontal_gap = 0 } = styleProperties;
        const slideshowStyles = buildSlideshowStyles({
          selectors: FAST_GALLERY_MAPPERS_SELECTORS,
          horizontalGap: +slideshow_horizontal_gap,
        }).cssObject;
        layoutStyles = slideshowStyles;
        break;

      default:
        break;
    }

    const mapperStyles = _.merge(
      {},
      resetContainerStyles,
      resetItemStyles,
      layoutStyles,
      itemStyles,
      selectorToCssCustomCss,
    );

    return mapperStyles;
  },
);

const css = withCompInfo<any, any>()(
  ['styleProperties'],
  ({ styleProperties }) => {
    const { layout = 'Custom Grid' } = styleProperties;
    switch (layout) {
      case 'Custom Grid':
        return {
          overflow: 'unset',
        };
      case 'Slider':
        return {
          overflow: 'scroll',
        };
      case 'Masonry':
        return {
          overflow: 'unset',
        };

      case 'Slideshow':
        return {
          overflow: 'unset',
        };

      default:
        return {
          overflow: 'unset',
        };
    }
  },
);

export default createComponentMapperModel({ props, css, selectorToCss });
