import type { MenuItemData } from '../../Menu.types';

type ParsePreview = {
  designPanelPreviewStateMap?: Record<string, string>;
  layoutPanelPreviewStateMap?: Record<string, string>;
  items: Array<MenuItemData>;
};

// TODO make it work with Layout Panel and Virtual Dropdowns as well
export const parsePreview = ({
  designPanelPreviewStateMap,
  layoutPanelPreviewStateMap,
  items,
}: ParsePreview) => {
  if (
    layoutPanelPreviewStateMap ||
    designPanelPreviewStateMap?.selectedPartId?.startsWith('dropdown')
  ) {
    return items.find(item => item.slot)?.slot;
  }
  return '';
};
