import * as React from 'react';
import styles from './style/ProductSpecification.component.scss';
import type { SpecificationGroupProps } from '../types';
import SpecificationItem from './SpecificationItem';

const SpecificationGroup: React.FC<SpecificationGroupProps> = ({ group }) => {
  return (
    <div className={styles.group}>
      <h5 className={styles.groupTitle}>{group.title}</h5>
      <dl className={styles.specList}>
        {group.items.map((item, itemIndex) => (
          <SpecificationItem key={itemIndex} item={item} />
        ))}
      </dl>
    </div>
  );
};

export default SpecificationGroup;
