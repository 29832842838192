import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { IComponentPreviewWrapper } from '@wix/editor-elements-types/thunderboltPreview';
import type { IProductSpecificationProps } from '../ProductSpecification.props';
import { defaultItems } from '../constants';

function withComponentPreview(
  WrappedComponent: React.ComponentType<IProductSpecificationProps>,
): IComponentPreviewWrapper<IProductSpecificationProps> {
  return ({ ...viewerProps }) => {
    return (
      <WrappedComponent
        {...viewerProps}
        items={
          viewerProps.items.length === 0 ? defaultItems : viewerProps.items
        }
      />
    );
  };
}

export default (
  ViewerComponent: React.ComponentType<IProductSpecificationProps>,
) => createComponentPreviewEntry(withComponentPreview(ViewerComponent));
