import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  getHeightInPixels,
  isBrowser,
} from '@wix/editor-elements-common-utils';
import type { CompInfo } from '@wix/editor-elements-types/thunderbolt';
import { getCompStyles, getUrl } from '../../../tpaGallery';
import type { TPAGallerySdkData } from '../../../tpaGallery/types';
import type {
  ImpressDefinition,
  ImpressPropsPlatformProps,
  ImpressPropsCarmiData,
  ImpressCSSVars,
} from '../Impress.types';
import {
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

import seedRandom from '../../TPAGallery/lib/js/seedrandom.min.js?resource';
import utils from '../../TPAGallery/lib/js/utils.js?resource';
import app from '../../TPAGallery/lib/js/app.proto.js?resource';
import html5Dataset from '../../TPAGallery/lib/js/html5-dataset.js?resource';
import classlist from '../../TPAGallery/lib/js/classList.js?resource';
import { AssetQueryParams, ScriptQueryParams } from './constants';
import impressScriptUrl from './assets/impress.js?resource';
import legacyGalleriesScriptUrl from './assets/wix.galleries.impress.js?resource';
import leftArrowImageUrl from './assets/arrow-left.png?resource';
import rightArrowImageUrl from './assets/arrow-right.png?resource';
import impressHtml from './assets/Impress.html?resource';

const compStyleKeys = {
  bcgColor1: 'color1',
  bcgColor2: 'color2',
  bcgColor3: 'color3',
  bcgColor4: 'color4',
  bcgColor5: 'color5',
  textColor: 'color6',
  descriptionColor: 'color7',
  textBackgroundColor: 'color8',
};

export const props = withCompInfo<
  ImpressPropsPlatformProps,
  ImpressDefinition,
  ImpressPropsCarmiData
>()(
  [...propsDependencies, 'styleProperties', 'siteColors'],
  (data, carmiProps) => {
    return {
      ...propsResolver<ImpressDefinition>(
        data,
        carmiProps,
        'Impress/Impress.html',
      ),
      currentIndex: 0,
      compStyles: getCompStyles(
        compStyleKeys,
        data.styleProperties,
        data.siteColors,
      ),
      ...getLocalImpress(data),
    };
  },
);

const getLocalImpress = (
  data: Pick<CompInfo<ImpressDefinition>, 'compId' | 'viewMode' | 'language'>,
) => {
  const { compId, viewMode, language } = data;
  const additionalParams = new URLSearchParams({
    [ScriptQueryParams.legacyGalleriesScriptPath]: new URL(
      legacyGalleriesScriptUrl,
    ).pathname,
    [ScriptQueryParams.impressScriptPath]: new URL(impressScriptUrl).pathname,
    [ScriptQueryParams.seedRandom]: new URL(seedRandom).pathname,
    [ScriptQueryParams.utils]: new URL(utils).pathname,
    [ScriptQueryParams.html5Dataset]: new URL(html5Dataset).pathname,
    [ScriptQueryParams.app]: new URL(app).pathname,
    [ScriptQueryParams.classlist]: new URL(classlist).pathname,
    [AssetQueryParams.leftArrowImagePath]: new URL(leftArrowImageUrl).pathname,
    [AssetQueryParams.rightArrowImagePath]: new URL(rightArrowImageUrl)
      .pathname,
  });

  if (isBrowser()) {
    additionalParams.append('origin', window.origin);
  }
  const baseUrl = getUrl(impressHtml, compId, viewMode, language);
  const overrideHtmlUrl = `${baseUrl}&${additionalParams}`;

  return { url: overrideHtmlUrl };
};

const sdkData = withCompInfo<TPAGallerySdkData, ImpressDefinition>()(
  ['compData'],
  ({ compData }) => {
    return {
      dataId: compData.id,
    };
  },
);

const css = withCompInfo<ImpressCSSVars, ImpressDefinition>()(
  ['compLayout', 'compSingleLayout', 'isOneDocMigrated'],
  ({ compLayout, compSingleLayout, isOneDocMigrated }) => {
    const height = isOneDocMigrated
      ? getHeightInPixels(compSingleLayout)
      : compLayout.height;
    return {
      '--height': `${height}px`,
    };
  },
);
export default createComponentMapperModel({ css, props, sdkData });
