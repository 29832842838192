import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  getPanelPreviewStateMap,
  getPartToPreviewStateMap,
} from '@wix/editor-elements-preview-utils';
import type {
  IMenuDefinition,
  IMenuMapperProps,
  IMenuCarmiData,
  IMenuPreviewMapperProps,
  IMenuPreviewWrapperProps,
} from '../Menu.types';
import { props as viewerPropsMapper } from './Menu.mapper';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';

export const props = composeMapper<
  IMenuMapperProps,
  IMenuDefinition,
  IMenuCarmiData
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState'],
  },
  (
    resolver,
    dependencies,
    carmiData,
  ): PreviewWrapperProps<IMenuPreviewMapperProps, IMenuPreviewWrapperProps> => {
    const viewerProps = resolver(dependencies, carmiData);
    const { compPreviewState } = dependencies;
    const designPanelPreviewStateMap = getPanelPreviewStateMap(
      compPreviewState,
      'designPanel',
    );
    const layoutPanelPreviewStateMap = getPanelPreviewStateMap(
      compPreviewState,
      'layoutPanel',
    );

    return {
      ...viewerProps,
      previewWrapperProps: {
        compPreviewState,
        designPanelPreviewStateMap,
        layoutPanelPreviewStateMap,
      },
      partToPreviewStateMap: getPartToPreviewStateMap(
        designPanelPreviewStateMap,
      ),
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
