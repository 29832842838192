import * as React from 'react';
import styles from './style/ProductSpecification.component.scss';
import type { SpecificationItemProps } from '../types';

const SpecificationItem: React.FC<SpecificationItemProps> = ({ item }) => {
  return (
    <div className={styles.specItem}>
      <dt className={styles.specTitle}>{item.title}</dt>
      <dd className={styles.specValue} data-label={item.title}>
        {item.value}
      </dd>
    </div>
  );
};

export default SpecificationItem;
