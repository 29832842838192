import React from 'react';
import {
  formatClassNames,
  INNER_FOCUS_RING_CLASSNAME,
} from '@wix/editor-elements-common-utils';

import type { ClassNames, BreadcrumbsProps } from '../../../Breadcrumbs.types';

import semanticClassNames from '../../../Breadcrumbs.semanticClassNames';
import styles from './UDPComponent.scss';
import BreadcrumbsCore from '../Breadcrumbs.core';

const Breadcrumbs: React.ForwardRefRenderFunction<
  HTMLDivElement,
  BreadcrumbsProps
> = (props, ref) => {
  const {
    className,
    customClassNames = [],
    showOnlyPreviousPageOnMobile,
  } = props;

  const contentClassName = showOnlyPreviousPageOnMobile
    ? styles['only-previous']
    : '';

  const classNames: ClassNames = {
    root: className,
    nav: `${styles.root} ${formatClassNames(
      semanticClassNames.root,
      ...customClassNames,
    )}`,
    ol: `${styles.list} ${contentClassName}`,
    icon: styles.icon,
    breadcrumb: styles.breadcrumb,
    breadcrumbIcon: `${styles.breadcrumb} ${styles['breadcrumb-icon']}`,
    separator: styles.separator,
    breadcrumbContent: `${styles['breadcrumb-content']} ${INNER_FOCUS_RING_CLASSNAME}`,
    breadcrumbContentCurrent: `${styles['breadcrumb-content']} ${INNER_FOCUS_RING_CLASSNAME} ${styles.current}`,
    label: styles.label,
  };

  return <BreadcrumbsCore {...props} ref={ref} classNames={classNames} />;
};

export default React.forwardRef(Breadcrumbs);
