import type { IFastGalleryProps } from './FastGallery.props';

export const FAST_GALLERY_MAPPERS_SELECTORS = {
  parentSelector: '.container',
  itemSelector: '.item',
};

export const defaultFastGalleryProps: Partial<IFastGalleryProps> = {
  itemClickAction: 'ACTION',
  layout: 'Custom Grid',
  custom_grid_pattern: '[[1,1,3],[2,1]]',
  custom_grid_opposite_pattern: '300px 400px',
  custom_grid_flip: false,
  custom_grid_mode: 'vertical',
  custom_grid_horizontal_gap: 0,
  custom_grid_vertical_gap: 0,
  slider_gap: 0,
  slider_item_width: 300,
  masonry_density: 10,
  masonry_horizontal_gaps: 0,
  masonry_vertical_gaps: 0,
  slideshow_horizontal_gap: 0,
  customCss: '',
  item_info_position: 'BELOW',
  item_image_fit: 'crop',
  enableTitle: true,
  item_elements_alignment: 'flex-start',
  downloadProtection: true,
};
