import type { ISpecificationGroup } from './types';

export const defaultItems: Array<ISpecificationGroup> = [
  {
    title: 'Product Dimensions',
    items: [
      {
        title: 'Product dimension (without stand)',
        value: '65.8" x 38.0" x 2.9"',
      },
      {
        title: 'Product dimension (with stand)',
        value: '65.8" x 40.6" x 13.4"',
      },
      { title: 'Weight (with stand)', value: '89.5 lbs' },
      { title: 'Package Dimensions (LxWxH)', value: '73.4" x 46.3" x 9.1"' },
      { title: 'Package Weight', value: '116.8 lbs' },
      { title: 'Weight (without stand)', value: '75.4 lbs' },
    ],
  },
  {
    title: 'Display',
    items: [
      { title: 'Actual Screen Size (measured diagonally) "', value: '74.5"' },
      { title: 'Screen type', value: 'LCD Mini-LED Pro+' },
      { title: 'Class', value: '75"' },
    ],
  },
  {
    title: 'Type of TV',
    items: [
      { title: 'Smart TV', value: 'Google TV' },
      { title: 'Works with Alexa', value: 'Yes' },
      { title: 'Google Assistant built-in', value: 'Yes' },
    ],
  },
  {
    title: 'Picture Quality',
    items: [
      { title: 'Screen resolution', value: '3840 x 2160' },
      { title: 'Motion Rate', value: '480' },
      { title: 'Display aspect ratio', value: '16:9' },
      { title: 'Backlight type', value: 'Mini-LED Pro+' },
      { title: 'Wide Color Gamut', value: 'Yes' },
      {
        title: 'HDR-compatible',
        value: 'Dolby Vision, HDR10, HDR10+, HLG, Advanced HDR',
      },
      { title: 'MEMC', value: 'Yes' },
      { title: 'Refresh rate', value: '144 Hz' },
    ],
  },
  {
    title: 'Audio',
    items: [
      { title: 'Audio output power (watts)', value: '50W' },
      { title: 'Audio Enhancement', value: 'Dolby Atmos' },
    ],
  },
  {
    title: 'Languages',
    items: [{ title: 'On-Screen Display', value: 'English, French, Spanish' }],
  },
  {
    title: 'Power',
    items: [
      { title: 'Power consumption', value: '350W' },
      { title: 'Standby consumption', value: '<0.5W' },
    ],
  },
  {
    title: 'Connectivity',
    items: [
      { title: 'Wireless built-in', value: 'Wifi 6E (802.11 ax)' },
      { title: 'Bluetooth ®', value: 'Yes (5.0)' },
    ],
  },
  {
    title: 'Ports',
    items: [
      { title: 'HDMI', value: '4 (2x2.1 4K@144Hz, 2x 2.0)' },
      { title: 'Ethernet (LAN)', value: 'Yes' },
      { title: 'USB', value: 'Yes (USB 2.0)' },
      { title: 'RF antenna', value: 'ATSC 3.0 (NextGen TV)' },
      { title: 'RCA composite video input', value: 'Yes' },
      { title: 'L/R audio input for composite', value: 'Yes' },
      { title: 'Digital audio output', value: 'Yes' },
      { title: 'Earphone/audio output', value: 'Yes' },
      { title: 'HDMI eARC', value: 'Yes' },
      { title: 'HDMI CEC', value: 'Yes' },
    ],
  },
  {
    title: 'Other Features',
    items: [
      { title: 'Noise reduction', value: 'Digital' },
      { title: 'Parental control', value: 'Yes' },
      { title: 'Closed caption', value: 'Yes' },
      { title: 'Sleep timer', value: 'Yes' },
    ],
  },
  {
    title: 'Accessories',
    items: [
      { title: 'Remote', value: 'Backlit Voice Remote' },
      { title: 'Quick Start Guide and/or User Manual', value: 'Yes' },
      { title: 'Power Cord', value: 'Yes' },
      { title: 'UPC', value: '888143017662' },
      { title: 'Warranty', value: '2 Yr' },
    ],
  },
  {
    title: 'Wall Mount',
    items: [{ title: 'VESA', value: '600 x 400 Mr' }],
  },
];
