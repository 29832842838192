import type { IRichContentViewerProps } from '../../RichContentViewer.types';
import * as React from 'react';

export const useHashtagConfig = ({
  hashtagHrefTemplate,
}: Pick<IRichContentViewerProps, 'hashtagHrefTemplate'>) => {
  const hashtagPluginConfig = React.useMemo(() => {
    if (hashtagHrefTemplate === undefined) {
      return null;
    }

    return {
      onClick: () => {},
      createHref: (hashtag: string) => {
        const normalisedHashtag = hashtag.startsWith('#')
          ? hashtag.substring(1)
          : hashtag;
        return hashtagHrefTemplate.replace(
          '{{hashtag}}',
          encodeURIComponent(normalisedHashtag.toLowerCase()),
        );
      },
    };
  }, [hashtagHrefTemplate]);

  return { hashtagPluginConfig };
};
